import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from '@/assets/icons/edit_20px.png'


const _hoisted_1 = { class: "box-card-detail" }
const _hoisted_2 = { class: "min-m-mt-40 max-m-mt-24" }
const _hoisted_3 = {
  key: 0,
  class: "core-value-conten card-box summary-container"
}
const _hoisted_4 = { class: "chart-name" }
const _hoisted_5 = { class: "chart-name" }
const _hoisted_6 = { class: "chart-name" }
const _hoisted_7 = { class: "chart-name" }
const _hoisted_8 = { class: "chart-name" }
const _hoisted_9 = { class: "chart-name two-line" }
const _hoisted_10 = {
  key: 1,
  id: "values-and-traits",
  style: {"margin-top":"16rem"}
}
const _hoisted_11 = { class: "card-box card-box-m" }
const _hoisted_12 = { class: "inner" }
const _hoisted_13 = { class: "text-header-card-wrapper" }
const _hoisted_14 = { class: "text-header-card" }
const _hoisted_15 = { class: "text-header-card-more" }
const _hoisted_16 = {
  key: 0,
  class: "text-time-wrapper"
}
const _hoisted_17 = {
  class: "text-spent-time",
  style: {"float":"right"}
}
const _hoisted_18 = { class: "time" }
const _hoisted_19 = {
  key: 1,
  class: "text-time-wrapper"
}
const _hoisted_20 = {
  class: "text-spent-time",
  style: {"float":"right","color":"#9e9e9e"}
}
const _hoisted_21 = { class: "overall-label" }
const _hoisted_22 = { key: 0 }
const _hoisted_23 = {
  key: 0,
  class: "flex-center"
}
const _hoisted_24 = {
  key: 1,
  class: "flex-center"
}
const _hoisted_25 = {
  key: 0,
  class: "grid-2-50-value-traits"
}
const _hoisted_26 = {
  key: 0,
  class: "card-box mt-24"
}
const _hoisted_27 = { key: 0 }
const _hoisted_28 = { class: "fs-16 mr-36" }
const _hoisted_29 = { class: "fs-16 fw-700 fc-1B1C1E" }
const _hoisted_30 = {
  class: "fs-14 mt-24",
  style: {"line-height":"160%"}
}
const _hoisted_31 = {
  key: 0,
  class: "fs-14 mt-24",
  style: {"line-height":"160%"}
}
const _hoisted_32 = {
  key: 0,
  class: "grid-tags max-m-mt-16"
}
const _hoisted_33 = ["id"]
const _hoisted_34 = { class: "ellipsis" }
const _hoisted_35 = {
  key: 1,
  class: "grid-tags"
}
const _hoisted_36 = {
  key: 2,
  class: "fs-16 mr-36 important-5-left",
  style: {"display":"none"}
}
const _hoisted_37 = { class: "fs-14 mt-8 italic" }
const _hoisted_38 = {
  key: 3,
  class: "grid-tags important-5",
  style: {"display":"none"}
}
const _hoisted_39 = { class: "ellipsis" }
const _hoisted_40 = {
  key: 4,
  class: "fs-12 fw-700 mt-24",
  style: {"line-height":"160%"}
}
const _hoisted_41 = {
  key: 5,
  class: "fs-12 mr-36"
}
const _hoisted_42 = {
  key: 0,
  class: "fs-14",
  style: {"line-height":"160%"}
}
const _hoisted_43 = {
  key: 1,
  class: "card-box mt-24 company"
}
const _hoisted_44 = { key: 0 }
const _hoisted_45 = { class: "fs-16 mr-36" }
const _hoisted_46 = { class: "fs-16 fw-700 fc-1B1C1E" }
const _hoisted_47 = {
  class: "fs-14 mt-24",
  style: {"line-height":"160%"}
}
const _hoisted_48 = {
  key: 0,
  class: "fs-14 mt-24",
  style: {"line-height":"160%"}
}
const _hoisted_49 = { class: "fs-12 fw-700 mt-24" }
const _hoisted_50 = {
  class: "fs-12 fw-400",
  style: {"color":"#696c80"}
}
const _hoisted_51 = { class: "grid-tags max-m-mt-16" }
const _hoisted_52 = ["id"]
const _hoisted_53 = { class: "fs-12 fw-700 mt-24" }
const _hoisted_54 = {
  class: "fs-12 fw-400",
  style: {"color":"#696c80"}
}
const _hoisted_55 = { class: "grid-tags max-m-mt-16" }
const _hoisted_56 = { class: "fs-12 fw-700 mt-24" }
const _hoisted_57 = {
  class: "fs-12 fw-400",
  style: {"color":"#696c80"}
}
const _hoisted_58 = { class: "grid-tags max-m-mt-16" }
const _hoisted_59 = {
  key: 0,
  class: "grid-tags max-m-mt-16",
  style: {"display":"none"}
}
const _hoisted_60 = { class: "ellipsis" }
const _hoisted_61 = {
  key: 1,
  class: "grid-tags",
  style: {"display":"none"}
}
const _hoisted_62 = {
  key: 2,
  class: "fs-16 mr-36 important-5-left"
}
const _hoisted_63 = { class: "fs-14 mt-8 italic" }
const _hoisted_64 = {
  key: 3,
  class: "grid-tags important-5"
}
const _hoisted_65 = { class: "ellipsis" }
const _hoisted_66 = {
  key: 1,
  class: "grid-2-50-value-traits"
}
const _hoisted_67 = {
  key: 0,
  class: "card-box mt-24"
}
const _hoisted_68 = { key: 0 }
const _hoisted_69 = { class: "fs-16 mr-36" }
const _hoisted_70 = { class: "fs-16 fw-700 fc-1B1C1E" }
const _hoisted_71 = {
  class: "fs-14 mt-24",
  style: {"line-height":"160%"}
}
const _hoisted_72 = {
  class: "fs-14 mt-24",
  style: {"line-height":"160%"}
}
const _hoisted_73 = {
  key: 0,
  class: "grid-tags max-m-mt-16"
}
const _hoisted_74 = { class: "ellipsis" }
const _hoisted_75 = {
  key: 1,
  class: "grid-tags"
}
const _hoisted_76 = {
  key: 2,
  class: "fs-16 mr-36 important-5-left"
}
const _hoisted_77 = { class: "fs-14 mt-8 italic" }
const _hoisted_78 = {
  key: 3,
  class: "grid-tags important-5"
}
const _hoisted_79 = { class: "ellipsis" }
const _hoisted_80 = {
  key: 1,
  class: "card-box mt-24"
}
const _hoisted_81 = { key: 0 }
const _hoisted_82 = { class: "fs-16 mr-36" }
const _hoisted_83 = { class: "fs-16 fw-700 fc-1B1C1E" }
const _hoisted_84 = {
  class: "fs-14 mt-24",
  style: {"line-height":"160%"}
}
const _hoisted_85 = {
  class: "fs-14 mt-24",
  style: {"line-height":"160%"}
}
const _hoisted_86 = {
  key: 0,
  class: "grid-tags max-m-mt-16"
}
const _hoisted_87 = { class: "ellipsis" }
const _hoisted_88 = {
  key: 1,
  class: "grid-tags"
}
const _hoisted_89 = {
  key: 2,
  class: "fs-16 mr-36 important-5-left"
}
const _hoisted_90 = { class: "fs-14 mt-8 italic" }
const _hoisted_91 = {
  key: 3,
  class: "grid-tags important-5"
}
const _hoisted_92 = { class: "ellipsis" }
const _hoisted_93 = {
  key: 2,
  class: "card-box card-box-m open-ended-question",
  style: {"margin-top":"2.4rem"}
}
const _hoisted_94 = { class: "title" }
const _hoisted_95 = { class: "anwser" }
const _hoisted_96 = {
  key: 2,
  id: "culture-profile",
  style: {"margin-top":"16rem"}
}
const _hoisted_97 = { class: "card-box card-box-l" }
const _hoisted_98 = { class: "inner" }
const _hoisted_99 = { class: "text-header-card-wrapper" }
const _hoisted_100 = { class: "text-header-card" }
const _hoisted_101 = { class: "text-header-card-more" }
const _hoisted_102 = {
  key: 0,
  class: "text-time-wrapper"
}
const _hoisted_103 = { class: "text-spent-time" }
const _hoisted_104 = { class: "time" }
const _hoisted_105 = {
  key: 1,
  class: "overall-label"
}
const _hoisted_106 = { class: "overall-title" }
const _hoisted_107 = { class: "overall-desc" }
const _hoisted_108 = { class: "culture-profile-container" }
const _hoisted_109 = {
  key: 0,
  class: "culture-profile-tabs-container"
}
const _hoisted_110 = ["onClick"]
const _hoisted_111 = { key: 0 }
const _hoisted_112 = { class: "tab-title" }
const _hoisted_113 = ["innerHTML"]
const _hoisted_114 = ["innerHTML"]
const _hoisted_115 = {
  key: 1,
  class: "culture-profile-tabs-container"
}
const _hoisted_116 = { class: "culture-profile-graph-container" }
const _hoisted_117 = {
  class: "candidate-container",
  style: {"height":"min-content"}
}
const _hoisted_118 = { class: "flex-center candidate-name" }
const _hoisted_119 = {
  class: "absolute-mid",
  style: {"left":"1rem"}
}
const _hoisted_120 = { class: "label ellipsis" }
const _hoisted_121 = { class: "flex-center candidate-name cursor-pointer" }
const _hoisted_122 = { class: "label ellipsis" }
const _hoisted_123 = { style: {"position":"relative","top":"36px"} }
const _hoisted_124 = { class: "chart-label-fixed" }
const _hoisted_125 = {
  class: "fs-14",
  style: {"width":"240px"}
}
const _hoisted_126 = ["innerHTML"]
const _hoisted_127 = { class: "header-text" }
const _hoisted_128 = { class: "sub-text" }
const _hoisted_129 = { class: "flex-center" }
const _hoisted_130 = { class: "chart-label-fixed" }
const _hoisted_131 = {
  class: "fs-14",
  style: {"width":"240px"}
}
const _hoisted_132 = ["innerHTML"]
const _hoisted_133 = { class: "header-text" }
const _hoisted_134 = { class: "sub-text" }
const _hoisted_135 = { style: {"width":"260px","height":"260px"} }
const _hoisted_136 = {
  ref: "culture_preference_chart",
  id: "culture_preference_chart",
  width: "260",
  height: "260"
}
const _hoisted_137 = { class: "chart-label-fixed" }
const _hoisted_138 = {
  class: "fs-14",
  style: {"width":"240px"}
}
const _hoisted_139 = ["innerHTML"]
const _hoisted_140 = { class: "header-text" }
const _hoisted_141 = { class: "sub-text" }
const _hoisted_142 = { class: "chart-label-fixed" }
const _hoisted_143 = {
  class: "fs-14",
  style: {"width":"240px"}
}
const _hoisted_144 = ["innerHTML"]
const _hoisted_145 = { class: "header-text" }
const _hoisted_146 = { class: "sub-text" }
const _hoisted_147 = {
  key: 0,
  class: "card-box"
}
const _hoisted_148 = { class: "text-header-card flex-center" }
const _hoisted_149 = {
  key: 0,
  class: "text-spent-time",
  style: {"float":"right","color":"#9e9e9e"}
}
const _hoisted_150 = { class: "time" }
const _hoisted_151 = {
  key: 1,
  class: "text-spent-time",
  style: {"float":"right","color":"#9e9e9e"}
}
const _hoisted_152 = { class: "overall-label" }
const _hoisted_153 = { key: 0 }
const _hoisted_154 = { class: "fs-16 fw-700 fc-000000" }
const _hoisted_155 = { class: "fs-16 mt-8" }
const _hoisted_156 = { class: "grid-2-45-55 mt-24" }
const _hoisted_157 = {
  key: 0,
  class: "mr-15"
}
const _hoisted_158 = ["onClick"]
const _hoisted_159 = { class: "culture-title" }
const _hoisted_160 = ["innerHTML"]
const _hoisted_161 = ["innerHTML"]
const _hoisted_162 = {
  key: 1,
  class: "mr-15"
}
const _hoisted_163 = { class: "flex-mid center rader-chart-box max-m-mb-32" }
const _hoisted_164 = { class: "chart-label-fixed" }
const _hoisted_165 = {
  class: "fs-14",
  style: {"width":"240px"}
}
const _hoisted_166 = ["innerHTML"]
const _hoisted_167 = { class: "header-text" }
const _hoisted_168 = { class: "sub-text" }
const _hoisted_169 = { class: "flex-center" }
const _hoisted_170 = { class: "chart-label-fixed" }
const _hoisted_171 = {
  class: "fs-14",
  style: {"width":"240px"}
}
const _hoisted_172 = ["innerHTML"]
const _hoisted_173 = { class: "header-text" }
const _hoisted_174 = { class: "sub-text" }
const _hoisted_175 = { style: {"width":"260px","height":"260px"} }
const _hoisted_176 = {
  ref: "culture_preference_chart",
  id: "culture_preference_chart",
  width: "260",
  height: "260"
}
const _hoisted_177 = { class: "chart-label-fixed" }
const _hoisted_178 = {
  class: "fs-14",
  style: {"width":"240px"}
}
const _hoisted_179 = ["innerHTML"]
const _hoisted_180 = { class: "header-text" }
const _hoisted_181 = { class: "sub-text" }
const _hoisted_182 = { class: "chart-label-fixed" }
const _hoisted_183 = {
  class: "fs-14",
  style: {"width":"240px"}
}
const _hoisted_184 = ["innerHTML"]
const _hoisted_185 = { class: "header-text" }
const _hoisted_186 = { class: "sub-text" }
const _hoisted_187 = { class: "ml-14 mr-26" }
const _hoisted_188 = { class: "ml-14" }
const _hoisted_189 = {
  key: 1,
  class: "card-box mt-24 open-ended-question"
}
const _hoisted_190 = { class: "title" }
const _hoisted_191 = { class: "anwser" }
const _hoisted_192 = {
  key: 3,
  id: "acceptable-behaviors",
  style: {"margin-top":"16rem"}
}
const _hoisted_193 = { class: "card-box card-box-m" }
const _hoisted_194 = { class: "inner" }
const _hoisted_195 = { style: {"display":"flex","flex-direction":"column","align-items":"flex-start","gap":"12px"} }
const _hoisted_196 = {
  class: "text-header-card-wrapper",
  style: {"width":"100%"}
}
const _hoisted_197 = { class: "text-header-card" }
const _hoisted_198 = { class: "text-header-card-more" }
const _hoisted_199 = {
  key: 0,
  class: "text-time-wrapper"
}
const _hoisted_200 = { class: "text-spent-time" }
const _hoisted_201 = { class: "time" }
const _hoisted_202 = {
  key: 1,
  class: "overall-label"
}
const _hoisted_203 = { style: {"color":"#696c80","font-size":"16px","font-weight":"400","line-height":"160%"} }
const _hoisted_204 = { style: {} }
const _hoisted_205 = {
  class: "acceptable-behaviors-grid",
  style: {"background":"rgba(105, 108, 128, 0.08)","border-radius":"1.6rem 1.6rem 0 0","border-width":"0.1rem","text-transform":"uppercase"}
}
const _hoisted_206 = {
  class: "thead text-content s-14 mr-16 fs-10 fw-700",
  style: {"padding":"1.8rem 2.4rem"}
}
const _hoisted_207 = { class: "fc-696C80" }
const _hoisted_208 = {
  class: "thead result fs-10 fw-700 fc-696C80 flex-center",
  style: {"padding":"1.8rem 2.4rem"}
}
const _hoisted_209 = {
  class: "thead result fs-10 fw-700 fc-696C80 flex-center",
  style: {"padding":"1.8rem 2.4rem"}
}
const _hoisted_210 = { key: 0 }
const _hoisted_211 = { class: "text-content" }
const _hoisted_212 = { class: "scenario-title" }
const _hoisted_213 = { class: "scenario-des" }
const _hoisted_214 = { class: "result flex-center" }
const _hoisted_215 = { class: "" }
const _hoisted_216 = {
  key: 0,
  class: "result flex-center"
}
const _hoisted_217 = { class: "" }
const _hoisted_218 = {
  key: 0,
  src: _imports_0,
  class: "opa-06 icon-16 ml-18",
  alt: "icons_edit_20px"
}
const _hoisted_219 = {
  width: "16",
  height: "16",
  viewBox: "0 0 16 16",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  style: {"margin-left":"16px","margin-top":"4px"}
}
const _hoisted_220 = {
  key: 1,
  class: "result flex-center"
}
const _hoisted_221 = { class: "" }
const _hoisted_222 = { key: 1 }
const _hoisted_223 = {
  key: 0,
  class: "card-box card-box-m mt-24 open-ended-question",
  style: {"margin-top":"2.4rem"}
}
const _hoisted_224 = { class: "title" }
const _hoisted_225 = { class: "anwser" }
const _hoisted_226 = {
  key: 4,
  id: "human-skills",
  style: {"margin-top":"16rem"}
}
const _hoisted_227 = {
  class: "card-box card-box-m",
  style: {"gap":"3.6rem"}
}
const _hoisted_228 = { class: "inner" }
const _hoisted_229 = { style: {"display":"flex","flex-direction":"column","align-items":"flex-start","gap":"12px"} }
const _hoisted_230 = {
  class: "text-header-card-wrapper",
  style: {"width":"100%"}
}
const _hoisted_231 = { class: "text-header-card" }
const _hoisted_232 = { class: "text-header-card-more" }
const _hoisted_233 = {
  key: 0,
  class: "text-time-wrapper"
}
const _hoisted_234 = { class: "text-spent-time" }
const _hoisted_235 = { class: "time" }
const _hoisted_236 = {
  key: 1,
  class: "overall-label"
}
const _hoisted_237 = {
  class: "fs-14",
  style: {"line-height":"160%"}
}
const _hoisted_238 = { class: "fw-700 fs-16 fc-1B1C1E" }
const _hoisted_239 = {
  key: 0,
  class: "mt-16 fs-16",
  style: {"line-height":"160%"}
}
const _hoisted_240 = {
  key: 1,
  class: "mt-16 fs-16"
}
const _hoisted_241 = { class: "mt-36 fw-700 fs-16 fc-1B1C1E" }
const _hoisted_242 = {
  key: 2,
  class: "mt-16 fs-16",
  style: {"line-height":"160%"}
}
const _hoisted_243 = {
  key: 3,
  class: "mt-16 fs-16"
}
const _hoisted_244 = { style: {"width":"872px","margin":"0 auto"} }
const _hoisted_245 = {
  key: 0,
  class: "mt-24 skills-container"
}
const _hoisted_246 = { class: "header" }
const _hoisted_247 = { key: 0 }
const _hoisted_248 = {
  key: 0,
  class: "bar-container flex-center"
}
const _hoisted_249 = {
  key: 0,
  class: "fs-12 fc-696C80 ml-auto"
}
const _hoisted_250 = {
  key: 1,
  class: "fs-12 ml-auto"
}
const _hoisted_251 = {
  class: "fs-12",
  style: {"line-height":"160%"}
}
const _hoisted_252 = {
  key: 1,
  class: "mt-24 skills-container"
}
const _hoisted_253 = { class: "bar-container flex-center opa-03" }
const _hoisted_254 = {
  key: 0,
  class: "card-box card-box-m mt-24 open-ended-question",
  style: {"margin-top":"2.4rem"}
}
const _hoisted_255 = { class: "title" }
const _hoisted_256 = { class: "anwser" }
const _hoisted_257 = {
  key: 5,
  id: "logic_test",
  style: {"margin-top":"16rem"}
}
const _hoisted_258 = { class: "card-box card-box-m" }
const _hoisted_259 = { class: "inner" }
const _hoisted_260 = { class: "text-header-card-wrapper" }
const _hoisted_261 = { class: "text-header-card" }
const _hoisted_262 = { class: "text-header-card-more" }
const _hoisted_263 = {
  key: 0,
  class: "text-time-wrapper"
}
const _hoisted_264 = { class: "text-spent-time" }
const _hoisted_265 = { class: "time" }
const _hoisted_266 = {
  key: 1,
  class: "overall-label"
}
const _hoisted_267 = { class: "logic-test-container" }
const _hoisted_268 = { class: "logic-test-graph-container" }
const _hoisted_269 = { style: {"padding":"20px"} }
const _hoisted_270 = { class: "chart-label-fixed" }
const _hoisted_271 = {
  class: "box-item",
  effect: "light",
  placement: "top"
}
const _hoisted_272 = {
  class: "box underline",
  style: {"width":"max-content"}
}
const _hoisted_273 = { class: "row" }
const _hoisted_274 = { class: "header-text" }
const _hoisted_275 = { class: "sub-text" }
const _hoisted_276 = { class: "flex-center" }
const _hoisted_277 = { class: "chart-label-fixed" }
const _hoisted_278 = {
  class: "box-item",
  effect: "light",
  placement: "top"
}
const _hoisted_279 = {
  class: "box underline",
  style: {"width":"max-content"}
}
const _hoisted_280 = { class: "header-text" }
const _hoisted_281 = { class: "sub-text" }
const _hoisted_282 = {
  ref: "logic_test_chart",
  width: "260",
  height: "260"
}
const _hoisted_283 = { class: "chart-label-fixed" }
const _hoisted_284 = {
  class: "box-item",
  effect: "light",
  placement: "top"
}
const _hoisted_285 = {
  class: "box underline",
  style: {"width":"max-content"}
}
const _hoisted_286 = { class: "header-text" }
const _hoisted_287 = { class: "sub-text" }
const _hoisted_288 = { class: "chart-label-fixed" }
const _hoisted_289 = {
  class: "box-item",
  effect: "light",
  placement: "top"
}
const _hoisted_290 = {
  class: "box underline",
  style: {"width":"max-content"}
}
const _hoisted_291 = { class: "header-text" }
const _hoisted_292 = { class: "sub-text" }
const _hoisted_293 = { class: "logic-test-tabs-container" }
const _hoisted_294 = { class: "logic-test-tab-wrapper" }
const _hoisted_295 = { class: "logic-test-tab" }
const _hoisted_296 = { class: "box underline" }
const _hoisted_297 = { class: "tab-title" }
const _hoisted_298 = {
  key: 0,
  class: "logic-test-sum-score"
}
const _hoisted_299 = { class: "sub-text" }
const _hoisted_300 = { class: "logic-test-tab" }
const _hoisted_301 = { class: "box underline" }
const _hoisted_302 = { class: "tab-title" }
const _hoisted_303 = {
  key: 0,
  class: "logic-test-sum-score"
}
const _hoisted_304 = { class: "sub-text" }
const _hoisted_305 = { class: "logic-test-tab" }
const _hoisted_306 = { class: "box underline" }
const _hoisted_307 = { class: "tab-title" }
const _hoisted_308 = {
  key: 0,
  class: "logic-test-sum-score"
}
const _hoisted_309 = { class: "sub-text" }
const _hoisted_310 = { class: "logic-test-tab" }
const _hoisted_311 = { class: "box underline" }
const _hoisted_312 = { class: "tab-title" }
const _hoisted_313 = {
  key: 0,
  class: "logic-test-sum-score"
}
const _hoisted_314 = { class: "sub-text" }
const _hoisted_315 = {
  key: 6,
  id: "custom_survey",
  style: {"margin-top":"16rem"}
}
const _hoisted_316 = { class: "card-box card-box-m" }
const _hoisted_317 = { class: "inner" }
const _hoisted_318 = { class: "text-header-card-wrapper" }
const _hoisted_319 = {
  key: 0,
  class: "text-header-card"
}
const _hoisted_320 = { class: "text-header-card-more" }
const _hoisted_321 = {
  key: 0,
  class: "text-time-wrapper"
}
const _hoisted_322 = { class: "text-spent-time" }
const _hoisted_323 = { class: "time" }
const _hoisted_324 = {
  key: 0,
  class: "fs-14",
  style: {"line-height":"160%"}
}
const _hoisted_325 = {
  key: 1,
  class: "custom-survey-container"
}
const _hoisted_326 = { class: "custom-survey-field-inner" }
const _hoisted_327 = { class: "custom-survey-field-num" }
const _hoisted_328 = { class: "custom-survey-field-content" }
const _hoisted_329 = { class: "custom-survey-field-question" }
const _hoisted_330 = { class: "type" }
const _hoisted_331 = { class: "title" }
const _hoisted_332 = { class: "description" }
const _hoisted_333 = {
  key: 0,
  width: "14",
  height: "15",
  viewBox: "0 0 14 15",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_334 = ["stroke"]
const _hoisted_335 = {
  key: 1,
  width: "14",
  height: "14",
  viewBox: "0 0 14 14",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_336 = {
  key: 0,
  width: "14",
  height: "14",
  viewBox: "0 0 14 14",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_337 = ["fill"]
const _hoisted_338 = {
  key: 1,
  width: "14",
  height: "14",
  viewBox: "0 0 14 14",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_339 = {
  key: 7,
  id: "work-style-identifier",
  style: {"margin-top":"20.1rem","display":"flex","align-items":"center","flex-direction":"column"}
}
const _hoisted_340 = { style: {"display":"inline-flex","align-items":"flex-start","gap":"64px","width":"872px"} }
const _hoisted_341 = { style: {} }
const _hoisted_342 = { class: "flex-mid rader-chart-box-pie" }
const _hoisted_343 = {
  class: "relative",
  style: {}
}
const _hoisted_344 = { ref: "work_style_chart" }
const _hoisted_345 = { class: "absolute-mid" }
const _hoisted_346 = {
  key: 0,
  class: "fs-12 center fc-696c80"
}
const _hoisted_347 = { class: "card-box card-box-m work-style-identifier" }
const _hoisted_348 = { class: "inner" }
const _hoisted_349 = {
  class: "text-header-card-wrapper",
  style: {"width":"100%"}
}
const _hoisted_350 = { class: "text-header-card" }
const _hoisted_351 = { class: "text-header-card-more" }
const _hoisted_352 = {
  key: 0,
  class: "text-time-wrapper"
}
const _hoisted_353 = { class: "text-spent-time" }
const _hoisted_354 = { class: "time" }
const _hoisted_355 = {
  key: 1,
  class: "overall-label"
}
const _hoisted_356 = {
  class: "fs-14 mt-8",
  style: {"line-height":"160%"}
}
const _hoisted_357 = { class: "value-conatiner mt-40" }
const _hoisted_358 = { class: "flex-center" }
const _hoisted_359 = { class: "fs-16 fw-700 fc-1B1C1E" }
const _hoisted_360 = {
  class: "fs-14 mt-16",
  style: {"line-height":"160%"}
}
const _hoisted_361 = { class: "value-conatiner mt-40" }
const _hoisted_362 = { class: "flex-center" }
const _hoisted_363 = { class: "fs-16 fw-700 fc-1B1C1E" }
const _hoisted_364 = {
  class: "fs-14 mt-16",
  style: {"line-height":"160%"}
}
const _hoisted_365 = { class: "value-conatiner mt-40" }
const _hoisted_366 = { class: "flex-center" }
const _hoisted_367 = { class: "fs-16 fw-700 fc-1B1C1E" }
const _hoisted_368 = {
  class: "fs-14 mt-16",
  style: {"line-height":"160%"}
}
const _hoisted_369 = {
  key: 0,
  class: "card-box card-box-m mt-24 open-ended-question",
  style: {"margin-top":"2.4rem"}
}
const _hoisted_370 = { class: "title" }
const _hoisted_371 = { class: "anwser" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_progress = _resolveComponent("el-progress")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")!
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")!
  const _component_el_dropdown = _resolveComponent("el-dropdown")!
  const _component_logic_test_scores = _resolveComponent("logic-test-scores")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.data && _ctx.data.summaries)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (_ctx.data.summaries.overall)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass(["chart-box", `bg-${_ctx.scoreCls(_ctx.data.summaries.overall)}-soft`])
                }, [
                  _createElementVNode("div", {
                    class: _normalizeClass(["chart-canvas-box", `bg-${_ctx.scoreCls(_ctx.data.summaries.overall)}-soft`])
                  }, [
                    _createVNode(_component_el_progress, {
                      type: "circle",
                      class: "absolute-mid",
                      "show-text": false,
                      "stroke-linecap": "square",
                      color: _ctx.overAllPercentageColorCode(_ctx.data.summaries.overall),
                      width: 180,
                      height: 180,
                      "stroke-width": 6,
                      percentage: _ctx.data.summaries.overall
                    }, null, 8, ["color", "percentage"]),
                    _createElementVNode("div", {
                      class: _normalizeClass(["absolute-mid label-inner flex-mid", `fc-${_ctx.scoreCls(_ctx.data.summaries.overall)}-results`])
                    }, [
                      _createElementVNode("div", null, [
                        _createElementVNode("div", null, _toDisplayString(_ctx.checkingNaN(_ctx.data.summaries.overall)) + "%", 1),
                        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t("assessmentResults.Overall")), 1)
                      ])
                    ], 2)
                  ], 2)
                ], 2))
              : _createCommentVNode("", true),
            (_ctx.data.summaries.values_and_traits)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  class: _normalizeClass(["chart-box", `bg-${_ctx.scoreCls(
            _ctx.data.summaries.values_and_traits.overall
          )}-soft`])
                }, [
                  _createElementVNode("div", {
                    class: _normalizeClass(["chart-canvas-box", `bg-${_ctx.scoreCls(
              _ctx.data.summaries.values_and_traits.overall
            )}-soft`])
                  }, [
                    _createVNode(_component_el_progress, {
                      type: "circle",
                      class: "absolute-mid",
                      "show-text": false,
                      "stroke-linecap": "square",
                      color: 
                _ctx.overAllPercentageColorCode(
                  _ctx.data.summaries.values_and_traits.overall
                )
              ,
                      width: 142,
                      height: 142,
                      "stroke-width": 6,
                      percentage: _ctx.data.summaries.values_and_traits.overall
                    }, null, 8, ["color", "percentage"]),
                    _createElementVNode("div", {
                      class: _normalizeClass(["absolute-mid label-inner flex-mid", `fc-${_ctx.scoreCls(
                _ctx.data.summaries.values_and_traits.overall
              )}-results`])
                    }, [
                      _createElementVNode("div", null, [
                        _createElementVNode("div", null, _toDisplayString(_ctx.checkingNaN(_ctx.data.summaries.values_and_traits.overall)) + "% ", 1),
                        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t("main.Values_Alignment")), 1)
                      ])
                    ], 2)
                  ], 2)
                ], 2))
              : _createCommentVNode("", true),
            (_ctx.data.summaries.oaic_archetypes)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 2,
                  class: _normalizeClass(["chart-box", `bg-${_ctx.scoreCls(_ctx.data.summaries.oaic_archetypes.overall)}-soft`])
                }, [
                  _createElementVNode("div", {
                    class: _normalizeClass(["chart-canvas-box", `bg-${_ctx.scoreCls(
              _ctx.data.summaries.oaic_archetypes.overall
            )}-soft`])
                  }, [
                    _createVNode(_component_el_progress, {
                      type: "circle",
                      class: "absolute-mid",
                      "show-text": false,
                      "stroke-linecap": "square",
                      color: 
                _ctx.overAllPercentageColorCode(
                  _ctx.data.summaries.oaic_archetypes.overall
                )
              ,
                      width: 142,
                      height: 142,
                      "stroke-width": 6,
                      percentage: _ctx.data.summaries.oaic_archetypes.overall
                    }, null, 8, ["color", "percentage"]),
                    _createElementVNode("div", {
                      class: _normalizeClass(["absolute-mid label-inner flex-mid", `fc-${_ctx.scoreCls(
                _ctx.data.summaries.oaic_archetypes.overall
              )}-results`])
                    }, [
                      _createElementVNode("div", null, [
                        _createElementVNode("div", null, _toDisplayString(_ctx.checkingNaN(_ctx.data.summaries.oaic_archetypes.overall)) + "% ", 1),
                        _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t("main.Culture_Profile")), 1)
                      ])
                    ], 2)
                  ], 2)
                ], 2))
              : _createCommentVNode("", true),
            (_ctx.data.summaries.human_skills)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 3,
                  class: _normalizeClass(["chart-box", `bg-${_ctx.scoreCls(_ctx.data.summaries.human_skills.overall)}-soft`])
                }, [
                  _createElementVNode("div", {
                    class: _normalizeClass(["chart-canvas-box", `bg-${_ctx.scoreCls(_ctx.data.summaries.human_skills.overall)}-soft`])
                  }, [
                    _createVNode(_component_el_progress, {
                      type: "circle",
                      class: "absolute-mid",
                      "show-text": false,
                      "stroke-linecap": "square",
                      color: 
                _ctx.overAllPercentageColorCode(_ctx.data.summaries.human_skills.overall)
              ,
                      width: 142,
                      height: 142,
                      "stroke-width": 6,
                      percentage: _ctx.data.summaries.human_skills.overall
                    }, null, 8, ["color", "percentage"]),
                    _createElementVNode("div", {
                      class: _normalizeClass(["absolute-mid label-inner flex-mid", `fc-${_ctx.scoreCls(
                _ctx.data.summaries.human_skills.overall
              )}-results`])
                    }, [
                      _createElementVNode("div", null, [
                        _createElementVNode("div", null, _toDisplayString(_ctx.checkingNaN(_ctx.data.summaries.human_skills.overall)) + "% ", 1),
                        _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t("main.Human_Skills")), 1)
                      ])
                    ], 2)
                  ], 2)
                ], 2))
              : _createCommentVNode("", true),
            (_ctx.data.summaries.logic_test)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 4,
                  class: _normalizeClass(["chart-box", `bg-${_ctx.scoreCls(_ctx.data.summaries.logic_test.overall)}-soft`])
                }, [
                  _createElementVNode("div", {
                    class: _normalizeClass(["chart-canvas-box", `bg-${_ctx.scoreCls(_ctx.data.summaries.logic_test.overall)}-soft`])
                  }, [
                    _createVNode(_component_el_progress, {
                      type: "circle",
                      class: "absolute-mid",
                      "show-text": false,
                      "stroke-linecap": "square",
                      color: 
                _ctx.overAllPercentageColorCode(_ctx.data.summaries.logic_test.overall)
              ,
                      width: 142,
                      height: 142,
                      "stroke-width": 6,
                      percentage: _ctx.data.summaries.logic_test.overall
                    }, null, 8, ["color", "percentage"]),
                    _createElementVNode("div", {
                      class: _normalizeClass(["absolute-mid label-inner flex-mid", `fc-${_ctx.scoreCls(
                _ctx.data.summaries.logic_test.overall
              )}-results`])
                    }, [
                      _createElementVNode("div", null, [
                        _createElementVNode("div", null, _toDisplayString(_ctx.checkingNaN(_ctx.data.summaries.logic_test.overall)) + "%", 1),
                        _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t("main.Logic_Test")), 1)
                      ])
                    ], 2)
                  ], 2)
                ], 2))
              : _createCommentVNode("", true),
            (_ctx.data.summaries.acceptable_behaviors)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 5,
                  class: _normalizeClass(["chart-box", `bg-${_ctx.scoreCls(
            _ctx.data.summaries.acceptable_behaviors.overall
          )}-soft`])
                }, [
                  _createElementVNode("div", {
                    class: _normalizeClass(["chart-canvas-box", `bg-${_ctx.scoreCls(
              _ctx.data.summaries.acceptable_behaviors.overall
            )}-soft`])
                  }, [
                    _createVNode(_component_el_progress, {
                      type: "circle",
                      class: "absolute-mid",
                      "show-text": false,
                      "stroke-linecap": "square",
                      color: 
                _ctx.overAllPercentageColorCode(
                  _ctx.data.summaries.acceptable_behaviors.overall
                )
              ,
                      width: 142,
                      height: 142,
                      "stroke-width": 6,
                      percentage: _ctx.data.summaries.acceptable_behaviors.overall
                    }, null, 8, ["color", "percentage"]),
                    _createElementVNode("div", {
                      class: _normalizeClass(["absolute-mid fs-18 fw-700", `fc-${_ctx.scoreCls(
                _ctx.data.summaries.acceptable_behaviors.overall
              )}-results`])
                    }, _toDisplayString(_ctx.checkingNaN(_ctx.data.summaries.acceptable_behaviors.overall)), 3),
                    _createElementVNode("div", {
                      class: _normalizeClass(["absolute-mid label-inner flex-mid", `fc-${_ctx.scoreCls(
                _ctx.data.summaries.acceptable_behaviors.overall
              )}-results`])
                    }, [
                      _createElementVNode("div", null, [
                        _createElementVNode("div", null, _toDisplayString(_ctx.checkingNaN(_ctx.data.summaries.acceptable_behaviors.overall)) + "% ", 1),
                        _createElementVNode("div", _hoisted_9, [
                          _createElementVNode("div", null, _toDisplayString(_ctx.$t("assessmentResults.Acceptable_")), 1),
                          _createElementVNode("div", null, _toDisplayString(_ctx.$t("assessmentResults.Behaviors_")), 1)
                        ])
                      ])
                    ], 2)
                  ], 2)
                ], 2))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      (_ctx.data.summaries && _ctx.data.summaries.values_and_traits)
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.$t("main.Values_Alignment")), 1),
                  _createElementVNode("div", _hoisted_15, [
                    (
                    !_ctx.isRespondent &&
                    _ctx.data.section_spent_times &&
                    _ctx.data.section_spent_times.values_and_traits !== null
                  )
                      ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                          _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.$t("assessmentResults.Completion_Time")) + ": ", 1),
                          _createElementVNode("span", _hoisted_18, _toDisplayString(_ctx.showHumanTime(
                        _ctx.data.section_spent_times.values_and_traits,
                        "values_and_traits"
                      )), 1)
                        ]))
                      : (_openBlock(), _createElementBlock("div", _hoisted_19, [
                          _createElementVNode("span", _hoisted_20, _toDisplayString(_ctx.$t("assessmentResults.Overall")) + ": " + _toDisplayString(Math.round(_ctx.data.summaries.values_and_traits.overall)) + "% ", 1)
                        ])),
                    _createElementVNode("div", _hoisted_21, [
                      (!_ctx.isRespondent)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_22, _toDisplayString(_ctx.$t("assessmentResults.Overall")) + ":", 1))
                        : _createCommentVNode("", true),
                      _createElementVNode("span", null, _toDisplayString(Math.round(_ctx.data.summaries.values_and_traits.overall)) + "%", 1)
                    ])
                  ])
                ]),
                (_ctx.isV2)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                      _createElementVNode("div", {
                        class: _normalizeClass(["value-box-bar", `bg-${_ctx.scoreCls(
                  _ctx.data.summaries.values_and_traits.overall
                )}-results`]),
                        "orig-style": "background-color: #ffc200",
                        style: _normalizeStyle(`width: ${_ctx.data.summaries.values_and_traits.overall}%`)
                      }, null, 6),
                      _createElementVNode("div", {
                        class: "value-box-bar",
                        style: _normalizeStyle([{"background-color":"#e6e8ec"}, `width: ${
                  100 - _ctx.data.summaries.values_and_traits.overall
                }%`])
                      }, null, 4)
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_24, [
                      _createElementVNode("div", {
                        class: _normalizeClass(["value-box-bar", `bg-${_ctx.scoreCls(
                  _ctx.data.summaries.values_and_traits.overall
                )}-results`]),
                        "orig-style": "background-color: #ffc200",
                        style: _normalizeStyle(`width: ${_ctx.data.summaries.values_and_traits.overall}%`)
                      }, null, 6),
                      _createElementVNode("div", {
                        class: "value-box-bar",
                        style: _normalizeStyle([{"background-color":"#e6e8ec"}, `width: ${
                  100 - _ctx.data.summaries.values_and_traits.overall
                }%`])
                      }, null, 4)
                    ]))
              ])
            ]),
            (_ctx.isV2)
              ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                  (_ctx.data.summaries && _ctx.data.summaries.values_and_traits)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                        (
                _ctx.data.summaries &&
                _ctx.data.summaries.values_and_traits &&
                _ctx.data.summaries.values_and_traits.defining_your_values
              )
                          ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
                              _createElementVNode("div", _hoisted_28, [
                                _createElementVNode("div", _hoisted_29, _toDisplayString(_ctx.t("assessmentResults.Values_of", _ctx.data.firstname)), 1),
                                _createElementVNode("div", _hoisted_30, _toDisplayString(// $t("assessmentResults.selection_of_the_candidate_values")
                    _ctx.t("assessmentResults.values_info", _ctx.focusUser.companyName)), 1),
                                false
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_31, _toDisplayString(_ctx.$t("assessmentResults.Our_values_guide_our")), 1))
                                  : _createCommentVNode("", true)
                              ]),
                              (_ctx.summaries && _ctx.summaries.values_and_traits)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.summaries.values_and_traits.scenarios[0]
                    .questions, (value) => {
                                      return (_openBlock(), _createElementBlock("div", {
                                        key: value.id,
                                        class: _normalizeClass(["tag-box flex-center", value.status]),
                                        id: value.id
                                      }, [
                                        _createElementVNode("div", _hoisted_34, _toDisplayString(_ctx.lang(value, "title")), 1)
                                      ], 10, _hoisted_33))
                                    }), 128))
                                  ]))
                                : (_openBlock(), _createElementBlock("div", _hoisted_35, [
                                    (_openBlock(), _createElementBlock(_Fragment, null, _renderList([1, 2, 3, 4, 5, 6], (value) => {
                                      return _createElementVNode("div", {
                                        key: value + '_mock_values_scenarios_question',
                                        class: "tag-box flex-center unselect"
                                      }, _cache[0] || (_cache[0] = [
                                        _createElementVNode("div", {
                                          class: "image-skeleton opa-03",
                                          style: {"width":"30%"}
                                        }, "   ", -1)
                                      ]))
                                    }), 64))
                                  ])),
                              (!_ctx.isRespondent)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_36, [
                                    _createElementVNode("div", _hoisted_37, _toDisplayString(_ctx.$t("question.valueAndTraits.Rated_very_important_by")) + " " + _toDisplayString(_ctx.focusUser.companyName) + " " + _toDisplayString(_ctx.$t("question.valueAndTraits.but_unselected_by")) + " " + _toDisplayString(_ctx.focusUser.firstname), 1)
                                  ]))
                                : _createCommentVNode("", true),
                              (!_ctx.isRespondent && _ctx.unselectedValueQuestions)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_38, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.unselectedValueQuestions, (question) => {
                                      return (_openBlock(), _createElementBlock("div", {
                                        key: question.id,
                                        class: _normalizeClass(["tag-box flex-center", 'red'])
                                      }, [
                                        _createElementVNode("div", _hoisted_39, _toDisplayString(_ctx.lang(question, "title")), 1)
                                      ]))
                                    }), 128))
                                  ]))
                                : _createCommentVNode("", true),
                              (
                  !_ctx.isRespondent &&
                  _ctx.data.summaries &&
                  _ctx.data.summaries.values_and_traits &&
                  _ctx.data.summaries.values_and_traits.values_alignment
                )
                                ? (_openBlock(), _createElementBlock("div", _hoisted_40, _toDisplayString(_ctx.$t("assessmentResults.Analysis")), 1))
                                : _createCommentVNode("", true),
                              (!_ctx.isRespondent)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_41, [
                                    (
                    _ctx.data.summaries &&
                    _ctx.data.summaries.values_and_traits &&
                    _ctx.data.summaries.values_and_traits.values_alignment
                  )
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_42, _toDisplayString(_ctx.getValueAlignment()), 1))
                                      : _createCommentVNode("", true)
                                  ]))
                                : _createCommentVNode("", true)
                            ]))
                          : _createCommentVNode("", true)
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.formTemplate.values_and_traits_v_2)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_43, [
                        (
                _ctx.data.summaries &&
                _ctx.data.summaries.values_and_traits &&
                _ctx.data.summaries.values_and_traits.identifying_key_traits
              )
                          ? (_openBlock(), _createElementBlock("div", _hoisted_44, [
                              _createElementVNode("div", _hoisted_45, [
                                _createElementVNode("div", _hoisted_46, _toDisplayString(_ctx.t("assessmentResults.Values_of", _ctx.focusUser.companyName)), 1),
                                _createElementVNode("div", _hoisted_47, _toDisplayString(_ctx.t("assessmentResults.Company_Values", _ctx.focusUser.companyName)), 1),
                                false
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_48, _toDisplayString(_ctx.$t("assessmentResults.Our_traits_guide_give")), 1))
                                  : _createCommentVNode("", true)
                              ]),
                              _createElementVNode("div", _hoisted_49, _toDisplayString(_ctx.$t("assessmentResults.Core_Values")), 1),
                              _createElementVNode("div", _hoisted_50, _toDisplayString(_ctx.$t("assessmentResults.Core_Values_desc")), 1),
                              _createElementVNode("div", _hoisted_51, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formTemplate.values_and_traits_v_2
                    .values.core_values, (value, qid) => {
                                  return (_openBlock(), _createElementBlock("div", {
                                    key: qid,
                                    class: _normalizeClass(["tag-box flex-center", _ctx.getCompanyClsByQid(qid, 'green')]),
                                    id: qid
                                  }, _toDisplayString(_ctx.getQuestionNameByQid(qid)), 11, _hoisted_52))
                                }), 128))
                              ]),
                              _createElementVNode("div", _hoisted_53, _toDisplayString(_ctx.$t("assessmentResults.Permission_to_play_values")), 1),
                              _createElementVNode("div", _hoisted_54, _toDisplayString(_ctx.$t("assessmentResults.Permission_to_play_values_desc")), 1),
                              _createElementVNode("div", _hoisted_55, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formTemplate.values_and_traits_v_2
                    .values.permission_to_play, (value, qid) => {
                                  return (_openBlock(), _createElementBlock("div", {
                                    key: qid,
                                    class: _normalizeClass(["tag-box flex-center", _ctx.getCompanyClsByQid(qid, 'green')])
                                  }, _toDisplayString(_ctx.getQuestionNameByQid(qid)), 3))
                                }), 128))
                              ]),
                              _createElementVNode("div", _hoisted_56, _toDisplayString(_ctx.$t("assessmentResults.Non_essential_values")), 1),
                              _createElementVNode("div", _hoisted_57, _toDisplayString(_ctx.$t("assessmentResults.Non_essential_values_desc")), 1),
                              _createElementVNode("div", _hoisted_58, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formTemplate.values_and_traits_v_2
                    .values.non_essential, (value, qid) => {
                                  return (_openBlock(), _createElementBlock("div", {
                                    key: qid,
                                    class: _normalizeClass(["tag-box flex-center", _ctx.getCompanyClsByQid(qid, 'red')])
                                  }, _toDisplayString(_ctx.getQuestionNameByQid(qid)), 3))
                                }), 128))
                              ]),
                              (_ctx.summaries && _ctx.summaries.values_and_traits)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_59, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.summaries.values_and_traits.scenarios[1]
                    .questions, (value) => {
                                      return (_openBlock(), _createElementBlock("div", {
                                        key: value.id,
                                        class: _normalizeClass(["tag-box flex-center", value.status])
                                      }, [
                                        _createElementVNode("div", _hoisted_60, _toDisplayString(_ctx.lang(value, "title")), 1)
                                      ], 2))
                                    }), 128))
                                  ]))
                                : (_openBlock(), _createElementBlock("div", _hoisted_61, [
                                    (_openBlock(), _createElementBlock(_Fragment, null, _renderList([1, 2, 3, 4, 5, 6], (value) => {
                                      return _createElementVNode("div", {
                                        key: value + '_mock_values_scenarios_question',
                                        class: "tag-box flex-center opa-03 five"
                                      }, _cache[1] || (_cache[1] = [
                                        _createElementVNode("div", {
                                          class: "image-skeleton",
                                          style: {"width":"30%"}
                                        }, " ", -1)
                                      ]))
                                    }), 64))
                                  ])),
                              (false && !_ctx.isRespondent)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_62, [
                                    _createElementVNode("div", _hoisted_63, _toDisplayString(_ctx.$t("question.valueAndTraits.Rated_very_important_by")) + " " + _toDisplayString(_ctx.focusUser.companyName) + " " + _toDisplayString(_ctx.$t("question.valueAndTraits.but_unselected_by")) + " " + _toDisplayString(_ctx.focusUser.firstname), 1)
                                  ]))
                                : _createCommentVNode("", true),
                              (false && !_ctx.isRespondent && _ctx.unselectedTraitQuestions)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_64, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.unselectedTraitQuestions, (question) => {
                                      return (_openBlock(), _createElementBlock("div", {
                                        key: question.id,
                                        class: _normalizeClass(["tag-box flex-center", 'red'])
                                      }, [
                                        _createElementVNode("div", _hoisted_65, _toDisplayString(_ctx.lang(question, "title")), 1)
                                      ]))
                                    }), 128))
                                  ]))
                                : _createCommentVNode("", true)
                            ]))
                          : _createCommentVNode("", true)
                      ]))
                    : _createCommentVNode("", true)
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_66, [
                  (_ctx.data.summaries && _ctx.data.summaries.values_and_traits)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_67, [
                        (
                _ctx.data.summaries &&
                _ctx.data.summaries.values_and_traits &&
                _ctx.data.summaries.values_and_traits.defining_your_values
              )
                          ? (_openBlock(), _createElementBlock("div", _hoisted_68, [
                              _createElementVNode("div", _hoisted_69, [
                                _createElementVNode("div", _hoisted_70, _toDisplayString(_ctx.$t("assessmentResults.Values")) + ": " + _toDisplayString(Math.round(
                      _ctx.data.summaries.values_and_traits.defining_your_values
                    )) + "% ", 1),
                                _createElementVNode("div", _hoisted_71, _toDisplayString(_ctx.$t("assessmentResults.selection_of_the_candidate_values")), 1),
                                _createElementVNode("div", _hoisted_72, _toDisplayString(_ctx.$t("assessmentResults.Our_values_guide_our")), 1)
                              ]),
                              (_ctx.summaries && _ctx.summaries.values_and_traits)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_73, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.summaries.values_and_traits.scenarios[0]
                    .questions, (value) => {
                                      return (_openBlock(), _createElementBlock("div", {
                                        key: value.id,
                                        class: _normalizeClass(["tag-box flex-center", value.status])
                                      }, [
                                        _createElementVNode("div", _hoisted_74, _toDisplayString(_ctx.lang(value, "title")), 1)
                                      ], 2))
                                    }), 128))
                                  ]))
                                : (_openBlock(), _createElementBlock("div", _hoisted_75, [
                                    (_openBlock(), _createElementBlock(_Fragment, null, _renderList([1, 2, 3, 4, 5, 6], (value) => {
                                      return _createElementVNode("div", {
                                        key: value + '_mock_values_scenarios_question',
                                        class: "tag-box flex-center opa-03 five"
                                      }, _cache[2] || (_cache[2] = [
                                        _createElementVNode("div", {
                                          class: "image-skeleton",
                                          style: {"width":"30%"}
                                        }, " ", -1)
                                      ]))
                                    }), 64))
                                  ])),
                              (!_ctx.isRespondent)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_76, [
                                    _createElementVNode("div", _hoisted_77, _toDisplayString(_ctx.$t("question.valueAndTraits.Rated_very_important_by")) + " " + _toDisplayString(_ctx.focusUser.companyName) + " " + _toDisplayString(_ctx.$t("question.valueAndTraits.but_unselected_by")) + " " + _toDisplayString(_ctx.focusUser.firstname), 1)
                                  ]))
                                : _createCommentVNode("", true),
                              (!_ctx.isRespondent && _ctx.unselectedValueQuestions)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_78, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.unselectedValueQuestions, (question) => {
                                      return (_openBlock(), _createElementBlock("div", {
                                        key: question.id,
                                        class: _normalizeClass(["tag-box flex-center", 'red'])
                                      }, [
                                        _createElementVNode("div", _hoisted_79, _toDisplayString(_ctx.lang(question, "title")), 1)
                                      ]))
                                    }), 128))
                                  ]))
                                : _createCommentVNode("", true)
                            ]))
                          : _createCommentVNode("", true)
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.data.summaries && _ctx.data.summaries.values_and_traits)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_80, [
                        (
                _ctx.data.summaries &&
                _ctx.data.summaries.values_and_traits &&
                _ctx.data.summaries.values_and_traits.identifying_key_traits
              )
                          ? (_openBlock(), _createElementBlock("div", _hoisted_81, [
                              _createElementVNode("div", _hoisted_82, [
                                _createElementVNode("div", _hoisted_83, _toDisplayString(_ctx.$t("assessmentResults.Traits")) + ": " + _toDisplayString(Math.round(
                      _ctx.data.summaries.values_and_traits.identifying_key_traits
                    )) + "% ", 1),
                                _createElementVNode("div", _hoisted_84, _toDisplayString(_ctx.$t("assessmentResults.This_is_the_selection")), 1),
                                _createElementVNode("div", _hoisted_85, _toDisplayString(_ctx.$t("assessmentResults.Our_traits_guide_give")), 1)
                              ]),
                              (_ctx.summaries && _ctx.summaries.values_and_traits)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_86, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.summaries.values_and_traits.scenarios[1]
                    .questions, (value) => {
                                      return (_openBlock(), _createElementBlock("div", {
                                        key: value.id,
                                        class: _normalizeClass(["tag-box flex-center", value.status])
                                      }, [
                                        _createElementVNode("div", _hoisted_87, _toDisplayString(_ctx.lang(value, "title")), 1)
                                      ], 2))
                                    }), 128))
                                  ]))
                                : (_openBlock(), _createElementBlock("div", _hoisted_88, [
                                    (_openBlock(), _createElementBlock(_Fragment, null, _renderList([1, 2, 3, 4, 5, 6], (value) => {
                                      return _createElementVNode("div", {
                                        key: value + '_mock_values_scenarios_question',
                                        class: "tag-box flex-center opa-03 five"
                                      }, _cache[3] || (_cache[3] = [
                                        _createElementVNode("div", {
                                          class: "image-skeleton",
                                          style: {"width":"30%"}
                                        }, " ", -1)
                                      ]))
                                    }), 64))
                                  ])),
                              (!_ctx.isRespondent)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_89, [
                                    _createElementVNode("div", _hoisted_90, _toDisplayString(_ctx.$t("question.valueAndTraits.Rated_very_important_by")) + " " + _toDisplayString(_ctx.focusUser.companyName) + " " + _toDisplayString(_ctx.$t("question.valueAndTraits.but_unselected_by")) + " " + _toDisplayString(_ctx.focusUser.firstname), 1)
                                  ]))
                                : _createCommentVNode("", true),
                              (!_ctx.isRespondent && _ctx.unselectedTraitQuestions)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_91, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.unselectedTraitQuestions, (question) => {
                                      return (_openBlock(), _createElementBlock("div", {
                                        key: question.id,
                                        class: _normalizeClass(["tag-box flex-center", 'red'])
                                      }, [
                                        _createElementVNode("div", _hoisted_92, _toDisplayString(_ctx.lang(question, "title")), 1)
                                      ]))
                                    }), 128))
                                  ]))
                                : _createCommentVNode("", true)
                            ]))
                          : _createCommentVNode("", true)
                      ]))
                    : _createCommentVNode("", true)
                ])),
            (
            Object.prototype.hasOwnProperty.call(
              _ctx.data,
              'values_and_traits_feedback'
            ) && _ctx.data.values_and_traits_feedback
          )
              ? (_openBlock(), _createElementBlock("div", _hoisted_93, [
                  _createElementVNode("div", _hoisted_94, _toDisplayString(_ctx.$t("assessmentTest.Anyz_feedback_or_thoughts")) + _toDisplayString(_ctx.$t("assessmentTest.values_and_traits_q")), 1),
                  _createElementVNode("div", _hoisted_95, _toDisplayString(_ctx.data.values_and_traits_feedback), 1)
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      (_ctx.data && _ctx.data.summaries && _ctx.data.summaries.oaic_archetypes)
        ? (_openBlock(), _createElementBlock("div", _hoisted_96, [
            _createElementVNode("div", _hoisted_97, [
              _createElementVNode("div", _hoisted_98, [
                _createElementVNode("div", _hoisted_99, [
                  _createElementVNode("div", _hoisted_100, _toDisplayString(_ctx.$t("main.Culture_Profile")), 1),
                  _createElementVNode("div", _hoisted_101, [
                    (
                    !_ctx.isRespondent &&
                    _ctx.data.section_spent_times &&
                    _ctx.data.section_spent_times.OAIC_archetypes !== null
                  )
                      ? (_openBlock(), _createElementBlock("div", _hoisted_102, [
                          _createElementVNode("span", _hoisted_103, _toDisplayString(_ctx.$t("assessmentResults.Completion_Time")) + ":", 1),
                          _createElementVNode("span", _hoisted_104, _toDisplayString(_ctx.showHumanTime(
                      _ctx.data.section_spent_times.OAIC_archetypes,
                      "OAIC_archetypes"
                    )), 1)
                        ]))
                      : _createCommentVNode("", true),
                    (!_ctx.isRespondent)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_105, [
                          _createElementVNode("span", null, _toDisplayString(_ctx.$t("assessmentResults.Overall")) + ":", 1),
                          _createElementVNode("span", null, _toDisplayString(Math.round(_ctx.data.summaries.oaic_archetypes.overall)) + "%", 1)
                        ]))
                      : _createCommentVNode("", true)
                  ])
                ]),
                (_ctx.data.summaries.oaic_archetypes.scenarios)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: _normalizeClass(["overall-culture-profile", `${_ctx.data.summaries.oaic_archetypes.scenarios[0].status}`])
                    }, [
                      _createElementVNode("div", _hoisted_106, _toDisplayString(_ctx.$t("assessmentResults.Overall")) + ": " + _toDisplayString(Math.round(_ctx.data.summaries.oaic_archetypes.overall)) + "% ", 1),
                      _createElementVNode("div", _hoisted_107, _toDisplayString(_ctx.$t("assessmentResults.The_overall_overlap_between")), 1)
                    ], 2))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_108, [
                  (_ctx.data.summaries.oaic_archetypes.scenarios)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_109, [
                        (_openBlock(), _createElementBlock(_Fragment, null, _renderList([0, 1, 2], (row) => {
                          return _createElementVNode("div", {
                            key: row,
                            class: "culture-profile-tab-wrapper"
                          }, [
                            (_openBlock(), _createElementBlock(_Fragment, null, _renderList([1, 2], (col) => {
                              return _createElementVNode("div", {
                                key: col,
                                class: _normalizeClass(["culture-profile-tab", `${
                      _ctx.data.summaries.oaic_archetypes.scenarios[row * 2 + col]
                        .status
                    } ${
                      _ctx.data.summaries.oaic_archetypes.scenarios[row * 2 + col]
                        .id == _ctx.culturePreferenceFocus.id
                        ? 'active'
                        : ''
                    }`]),
                                onClick: ($event: any) => (
                      _ctx.drilldownOnCulture(
                        _ctx.data.summaries.oaic_archetypes.scenarios[row * 2 + col]
                      )
                    )
                              }, [
                                (
                        _ctx.data.summaries.oaic_archetypes.scenarios[row * 2 + col]
                      )
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_111, [
                                      _createElementVNode("div", _hoisted_112, [
                                        _cache[4] || (_cache[4] = _createElementVNode("span", { class: "bullet" }, null, -1)),
                                        _createTextVNode(" " + _toDisplayString(_ctx.lang(
                            _ctx.data.summaries.oaic_archetypes.scenarios[
                              row * 2 + col
                            ],
                            "title"
                          )), 1)
                                      ]),
                                      (_ctx.$i18n.getLocale(0) == 'en')
                                        ? (_openBlock(), _createElementBlock("div", {
                                            key: 0,
                                            class: "tab-desc",
                                            innerHTML: 
                          _ctx.data.summaries.oaic_archetypes.scenarios[
                            row * 2 + col
                          ].sum_description
                        
                                          }, null, 8, _hoisted_113))
                                        : _createCommentVNode("", true),
                                      (_ctx.$i18n.getLocale(0) == 'th')
                                        ? (_openBlock(), _createElementBlock("div", {
                                            key: 1,
                                            class: "culture-des",
                                            innerHTML: 
                          _ctx.data.summaries.oaic_archetypes.scenarios[
                            row * 2 + col
                          ].sum_description_th
                        
                                          }, null, 8, _hoisted_114))
                                        : _createCommentVNode("", true)
                                    ]))
                                  : _createCommentVNode("", true)
                              ], 10, _hoisted_110)
                            }), 64))
                          ])
                        }), 64))
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_115, [
                        (_openBlock(), _createElementBlock(_Fragment, null, _renderList([0, 1, 2], (row) => {
                          return _createElementVNode("div", {
                            key: row,
                            class: "culture-profile-tab-wrapper"
                          }, [
                            (_openBlock(), _createElementBlock(_Fragment, null, _renderList([1, 2], (col) => {
                              return _createElementVNode("div", {
                                key: col,
                                class: "culture-profile-tab"
                              }, _cache[5] || (_cache[5] = [
                                _createStaticVNode("<div data-v-8efd2cc8><div class=\"tab-title\" style=\"display:flex;width:100%;\" data-v-8efd2cc8><div class=\"text-skeleton\" style=\"width:60%;\" data-v-8efd2cc8>   </div></div><div class=\"tab-desc\" style=\"gap:4px;\" data-v-8efd2cc8><div class=\"text-skeleton\" style=\"width:100%;\" data-v-8efd2cc8>   </div><div class=\"text-skeleton\" style=\"width:80%;\" data-v-8efd2cc8>   </div></div></div>", 1)
                              ]))
                            }), 64))
                          ])
                        }), 64))
                      ])),
                  _createElementVNode("div", _hoisted_116, [
                    _createElementVNode("div", _hoisted_117, [
                      _createElementVNode("div", _hoisted_118, [
                        _createElementVNode("div", _hoisted_119, [
                          _createElementVNode("div", {
                            class: "candidate-line",
                            style: _normalizeStyle({ 'border-color': _ctx.companyColor })
                          }, null, 4)
                        ]),
                        _createElementVNode("div", _hoisted_120, _toDisplayString(_ctx.focusUser.companyName), 1)
                      ]),
                      _createElementVNode("div", _hoisted_121, [
                        _cache[6] || (_cache[6] = _createElementVNode("div", {
                          class: "absolute-mid",
                          style: {"left":"1rem"}
                        }, [
                          _createElementVNode("div", {
                            class: "candidate-line",
                            style: `border-color: #28be79;`
                          })
                        ], -1)),
                        _createElementVNode("div", _hoisted_122, _toDisplayString(_ctx.focusUser.firstname), 1)
                      ])
                    ]),
                    _createElementVNode("div", {
                      class: _normalizeClass(`${_ctx.tooltipOncultureStatus ? 'opa-03' : ''}`),
                      style: {"width":"380px","height":"380px","margin":"39px auto"}
                    }, [
                      _createElementVNode("div", _hoisted_123, [
                        _createElementVNode("div", _hoisted_124, [
                          _createVNode(_component_el_tooltip, {
                            class: "box-item",
                            effect: "light",
                            placement: "top",
                            disabled: _ctx.tooltipOncultureStatus
                          }, {
                            content: _withCtx(() => [
                              _createElementVNode("div", _hoisted_125, [
                                _createElementVNode("span", { innerHTML: _ctx.tooltipOncultureLabelPrefix }, null, 8, _hoisted_126),
                                _createElementVNode("span", null, _toDisplayString(_ctx.tooltipOncultureLabel.collaborate), 1)
                              ])
                            ]),
                            default: _withCtx(() => [
                              _createElementVNode("div", {
                                class: _normalizeClass(["box", {
                            underline: true || !_ctx.tooltipOncultureStatus,
                          }])
                              }, [
                                _createElementVNode("div", _hoisted_127, _toDisplayString(_ctx.$t("assessmentResults.Collaborate")), 1),
                                _createElementVNode("div", _hoisted_128, _toDisplayString(_ctx.$t("assessmentResults.people_oriented")), 1)
                              ], 2)
                            ]),
                            _: 1
                          }, 8, ["disabled"])
                        ]),
                        _createElementVNode("div", _hoisted_129, [
                          _createElementVNode("div", _hoisted_130, [
                            _createVNode(_component_el_tooltip, {
                              class: "box-item",
                              effect: "light",
                              placement: "top",
                              disabled: _ctx.tooltipOncultureStatus
                            }, {
                              content: _withCtx(() => [
                                _createElementVNode("div", _hoisted_131, [
                                  _createElementVNode("span", { innerHTML: _ctx.tooltipOncultureLabelPrefix }, null, 8, _hoisted_132),
                                  _createElementVNode("span", null, _toDisplayString(_ctx.tooltipOncultureLabel.control), 1)
                                ])
                              ]),
                              default: _withCtx(() => [
                                _createElementVNode("div", {
                                  class: _normalizeClass(["box", {
                              underline: true || !_ctx.tooltipOncultureStatus,
                            }]),
                                  style: {"left":"3rem"}
                                }, [
                                  _createElementVNode("div", _hoisted_133, _toDisplayString(_ctx.$t("assessmentResults.Control")), 1),
                                  _createElementVNode("div", _hoisted_134, _toDisplayString(_ctx.$t("assessmentResults.process_oriented")), 1)
                                ], 2)
                              ]),
                              _: 1
                            }, 8, ["disabled"])
                          ]),
                          _createElementVNode("div", _hoisted_135, [
                            _createElementVNode("canvas", _hoisted_136, null, 512)
                          ]),
                          _createElementVNode("div", _hoisted_137, [
                            _createVNode(_component_el_tooltip, {
                              class: "box-item",
                              effect: "light",
                              placement: "top",
                              disabled: _ctx.tooltipOncultureStatus
                            }, {
                              content: _withCtx(() => [
                                _createElementVNode("div", _hoisted_138, [
                                  _createElementVNode("span", { innerHTML: _ctx.tooltipOncultureLabelPrefix }, null, 8, _hoisted_139),
                                  _createElementVNode("span", null, _toDisplayString(_ctx.tooltipOncultureLabel.create), 1)
                                ])
                              ]),
                              default: _withCtx(() => [
                                _createElementVNode("div", {
                                  class: _normalizeClass(["box", {
                              underline: true || !_ctx.tooltipOncultureStatus,
                            }])
                                }, [
                                  _createElementVNode("div", _hoisted_140, _toDisplayString(_ctx.$t("assessmentResults.Create")), 1),
                                  _createElementVNode("div", _hoisted_141, _toDisplayString(_ctx.$t("assessmentResults.dynamic_entrepreneurial")), 1)
                                ], 2)
                              ]),
                              _: 1
                            }, 8, ["disabled"])
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_142, [
                          _createVNode(_component_el_tooltip, {
                            class: "box-item",
                            effect: "light",
                            placement: "top",
                            disabled: _ctx.tooltipOncultureStatus
                          }, {
                            content: _withCtx(() => [
                              _createElementVNode("div", _hoisted_143, [
                                _createElementVNode("span", { innerHTML: _ctx.tooltipOncultureLabelPrefix }, null, 8, _hoisted_144),
                                _createElementVNode("span", null, _toDisplayString(_ctx.tooltipOncultureLabel.compete), 1)
                              ])
                            ]),
                            default: _withCtx(() => [
                              _createElementVNode("div", {
                                class: _normalizeClass(["box", {
                            underline: true || !_ctx.tooltipOncultureStatus,
                          }])
                              }, [
                                _createElementVNode("div", _hoisted_145, _toDisplayString(_ctx.$t("assessmentResults.Compete")), 1),
                                _createElementVNode("div", _hoisted_146, _toDisplayString(_ctx.$t("assessmentResults.results_oriented")), 1)
                              ], 2)
                            ]),
                            _: 1
                          }, 8, ["disabled"])
                        ])
                      ])
                    ], 2)
                  ])
                ])
              ])
            ]),
            (false && _ctx.data.summaries && _ctx.data.summaries.oaic_archetypes)
              ? (_openBlock(), _createElementBlock("div", _hoisted_147, [
                  _createElementVNode("div", _hoisted_148, [
                    _createTextVNode(_toDisplayString(_ctx.$t("main.Culture_Profile")) + " ", 1),
                    (
                !_ctx.isRespondent &&
                _ctx.data.section_spent_times &&
                _ctx.data.section_spent_times.OAIC_archetypes !== null
              )
                      ? (_openBlock(), _createElementBlock("span", _hoisted_149, [
                          _createTextVNode(_toDisplayString(_ctx.$t("assessmentResults.Completion_Time")) + ": ", 1),
                          _createElementVNode("span", _hoisted_150, _toDisplayString(_ctx.showHumanTime(
                    _ctx.data.section_spent_times.OAIC_archetypes,
                    "OAIC_archetypes"
                  )), 1)
                        ]))
                      : (_openBlock(), _createElementBlock("span", _hoisted_151, _toDisplayString(_ctx.$t("assessmentResults.Overall")) + ": " + _toDisplayString(Math.round(_ctx.data.summaries.oaic_archetypes.overall)) + "% ", 1))
                  ]),
                  _createElementVNode("div", _hoisted_152, [
                    (!_ctx.isRespondent)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_153, _toDisplayString(_ctx.$t("assessmentResults.Overall")) + ": " + _toDisplayString(Math.round(_ctx.data.summaries.oaic_archetypes.overall)) + "%", 1))
                      : _createCommentVNode("", true)
                  ]),
                  (_ctx.data.summaries.oaic_archetypes.scenarios)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: _normalizeClass(["overall-culture-profile mt-12", `${_ctx.data.summaries.oaic_archetypes.scenarios[0].status}`])
                      }, [
                        _createElementVNode("div", _hoisted_154, _toDisplayString(_ctx.$t("assessmentResults.Overall")) + ": " + _toDisplayString(Math.round(_ctx.data.summaries.oaic_archetypes.overall)) + "% ", 1),
                        _createElementVNode("div", _hoisted_155, _toDisplayString(_ctx.$t("assessmentResults.The_overall_overlap_between")), 1)
                      ], 2))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_156, [
                    (_ctx.data.summaries.oaic_archetypes.scenarios)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_157, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.summaries.oaic_archetypes.scenarios, (scenario) => {
                            return (_openBlock(), _createElementBlock("div", {
                              key: scenario.id,
                              onClick: ($event: any) => (_ctx.drilldownOnCulture(scenario)),
                              class: _normalizeClass([`${scenario.status} ${
                  scenario.id == _ctx.culturePreferenceFocus.id ? 'active-card' : ''
                } ${scenario.id == 'overall' ? 'd-none' : ''}`, "culture-card flex-center"])
                            }, [
                              _createElementVNode("div", null, [
                                _createElementVNode("div", _hoisted_159, _toDisplayString(_ctx.lang(scenario, "title")) + " (" + _toDisplayString(Math.round(scenario.value)) + "%) ", 1),
                                (_ctx.$i18n.getLocale(0) == 'en')
                                  ? (_openBlock(), _createElementBlock("div", {
                                      key: 0,
                                      class: "culture-des mt-16",
                                      innerHTML: scenario.sum_description
                                    }, null, 8, _hoisted_160))
                                  : _createCommentVNode("", true),
                                (_ctx.$i18n.getLocale(0) == 'th')
                                  ? (_openBlock(), _createElementBlock("div", {
                                      key: 1,
                                      class: "culture-des mt-16",
                                      innerHTML: scenario.sum_description_th
                                    }, null, 8, _hoisted_161))
                                  : _createCommentVNode("", true)
                              ])
                            ], 10, _hoisted_158))
                          }), 128))
                        ]))
                      : (_openBlock(), _createElementBlock("div", _hoisted_162, [
                          (_openBlock(), _createElementBlock(_Fragment, null, _renderList([1, 2, 3, 4, 5, 6, 7], (scenario) => {
                            return _createElementVNode("div", {
                              key: scenario,
                              class: "culture-card flex-center skeleton"
                            }, _cache[7] || (_cache[7] = [
                              _createStaticVNode("<div style=\"width:100%;\" data-v-8efd2cc8><div class=\"culture-title text-skeleton\" style=\"width:40%;\" data-v-8efd2cc8>   </div><div class=\"culture-des mt-10 opa-06\" style=\"width:100%;\" data-v-8efd2cc8><div class=\"text-skeleton fs-10 mt-4\" style=\"width:90%;\" data-v-8efd2cc8>   </div><div class=\"text-skeleton fs-10 mt-4\" style=\"width:80%;\" data-v-8efd2cc8>   </div></div></div>", 1)
                            ]))
                          }), 64))
                        ])),
                    _createElementVNode("div", _hoisted_163, [
                      _createElementVNode("div", null, [
                        _createElementVNode("div", {
                          class: _normalizeClass([`${_ctx.tooltipOncultureStatus ? 'opa-03' : ''}`, "mt-40 m-auto"]),
                          style: {"width":"380px","height":"380px"}
                        }, [
                          _createElementVNode("div", _hoisted_164, [
                            _createVNode(_component_el_tooltip, {
                              class: "box-item",
                              effect: "light",
                              placement: "top",
                              disabled: _ctx.tooltipOncultureStatus
                            }, {
                              content: _withCtx(() => [
                                _createElementVNode("div", _hoisted_165, [
                                  _createElementVNode("span", { innerHTML: _ctx.tooltipOncultureLabelPrefix }, null, 8, _hoisted_166),
                                  _createElementVNode("span", null, _toDisplayString(_ctx.tooltipOncultureLabel.collaborate), 1)
                                ])
                              ]),
                              default: _withCtx(() => [
                                _createElementVNode("div", {
                                  class: _normalizeClass(["box", {
                          underline: true || !_ctx.tooltipOncultureStatus,
                        }])
                                }, [
                                  _createElementVNode("div", _hoisted_167, _toDisplayString(_ctx.$t("assessmentResults.Collaborate")), 1),
                                  _createElementVNode("div", _hoisted_168, _toDisplayString(_ctx.$t("assessmentResults.people_oriented")), 1)
                                ], 2)
                              ]),
                              _: 1
                            }, 8, ["disabled"])
                          ]),
                          _createElementVNode("div", _hoisted_169, [
                            _createElementVNode("div", _hoisted_170, [
                              _createVNode(_component_el_tooltip, {
                                class: "box-item",
                                effect: "light",
                                placement: "top",
                                disabled: _ctx.tooltipOncultureStatus
                              }, {
                                content: _withCtx(() => [
                                  _createElementVNode("div", _hoisted_171, [
                                    _createElementVNode("span", { innerHTML: _ctx.tooltipOncultureLabelPrefix }, null, 8, _hoisted_172),
                                    _createElementVNode("span", null, _toDisplayString(_ctx.tooltipOncultureLabel.control), 1)
                                  ])
                                ]),
                                default: _withCtx(() => [
                                  _createElementVNode("div", {
                                    class: _normalizeClass(["box", {
                            underline: true || !_ctx.tooltipOncultureStatus,
                          }]),
                                    style: {"left":"3rem"}
                                  }, [
                                    _createElementVNode("div", _hoisted_173, _toDisplayString(_ctx.$t("assessmentResults.Control")), 1),
                                    _createElementVNode("div", _hoisted_174, _toDisplayString(_ctx.$t("assessmentResults.process_oriented")), 1)
                                  ], 2)
                                ]),
                                _: 1
                              }, 8, ["disabled"])
                            ]),
                            _createElementVNode("div", _hoisted_175, [
                              _createElementVNode("canvas", _hoisted_176, null, 512)
                            ]),
                            _createElementVNode("div", _hoisted_177, [
                              _createVNode(_component_el_tooltip, {
                                class: "box-item",
                                effect: "light",
                                placement: "top",
                                disabled: _ctx.tooltipOncultureStatus
                              }, {
                                content: _withCtx(() => [
                                  _createElementVNode("div", _hoisted_178, [
                                    _createElementVNode("span", { innerHTML: _ctx.tooltipOncultureLabelPrefix }, null, 8, _hoisted_179),
                                    _createElementVNode("span", null, _toDisplayString(_ctx.tooltipOncultureLabel.create), 1)
                                  ])
                                ]),
                                default: _withCtx(() => [
                                  _createElementVNode("div", {
                                    class: _normalizeClass(["box", {
                            underline: true || !_ctx.tooltipOncultureStatus,
                          }])
                                  }, [
                                    _createElementVNode("div", _hoisted_180, _toDisplayString(_ctx.$t("assessmentResults.Create")), 1),
                                    _createElementVNode("div", _hoisted_181, _toDisplayString(_ctx.$t("assessmentResults.dynamic_entrepreneurial")), 1)
                                  ], 2)
                                ]),
                                _: 1
                              }, 8, ["disabled"])
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_182, [
                            _createVNode(_component_el_tooltip, {
                              class: "box-item",
                              effect: "light",
                              placement: "top",
                              disabled: _ctx.tooltipOncultureStatus
                            }, {
                              content: _withCtx(() => [
                                _createElementVNode("div", _hoisted_183, [
                                  _createElementVNode("span", { innerHTML: _ctx.tooltipOncultureLabelPrefix }, null, 8, _hoisted_184),
                                  _createElementVNode("span", null, _toDisplayString(_ctx.tooltipOncultureLabel.compete), 1)
                                ])
                              ]),
                              default: _withCtx(() => [
                                _createElementVNode("div", {
                                  class: _normalizeClass(["box", {
                          underline: true || !_ctx.tooltipOncultureStatus,
                        }])
                                }, [
                                  _createElementVNode("div", _hoisted_185, _toDisplayString(_ctx.$t("assessmentResults.Compete")), 1),
                                  _createElementVNode("div", _hoisted_186, _toDisplayString(_ctx.$t("assessmentResults.results_oriented")), 1)
                                ], 2)
                              ]),
                              _: 1
                            }, 8, ["disabled"])
                          ])
                        ], 2),
                        _createElementVNode("div", {
                          class: _normalizeClass([`${_ctx.tooltipOncultureStatus ? 'opa-03' : ''}`, "flex-mid fs-12 fw-500"])
                        }, [
                          _cache[8] || (_cache[8] = _createElementVNode("div", { class: "box user-box ml-10" }, null, -1)),
                          _createElementVNode("div", _hoisted_187, _toDisplayString(_ctx.focusUser.firstname), 1),
                          _cache[9] || (_cache[9] = _createElementVNode("div", { class: "box company-box border-primary" }, null, -1)),
                          _createElementVNode("div", _hoisted_188, _toDisplayString(_ctx.focusUser.companyName), 1)
                        ], 2)
                      ])
                    ])
                  ])
                ]))
              : _createCommentVNode("", true),
            (
            Object.prototype.hasOwnProperty.call(
              _ctx.data,
              'oaic_archetypes_feedback'
            ) && _ctx.data.oaic_archetypes_feedback
          )
              ? (_openBlock(), _createElementBlock("div", _hoisted_189, [
                  _createElementVNode("div", _hoisted_190, _toDisplayString(_ctx.$t("assessmentTest.Anyz_feedback_or_thoughts")) + _toDisplayString(_ctx.$t("assessmentTest.culture_profile_q")), 1),
                  _createElementVNode("div", _hoisted_191, _toDisplayString(_ctx.data.oaic_archetypes_feedback), 1)
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      (_ctx.data.summaries && _ctx.hasAcceptableBehaviors)
        ? (_openBlock(), _createElementBlock("div", _hoisted_192, [
            _createElementVNode("div", _hoisted_193, [
              _createElementVNode("div", _hoisted_194, [
                _createElementVNode("div", _hoisted_195, [
                  _createElementVNode("div", _hoisted_196, [
                    _createElementVNode("div", _hoisted_197, _toDisplayString(_ctx.$t("main.Acceptable_Behaviors")), 1),
                    _createElementVNode("div", _hoisted_198, [
                      (
                      !_ctx.isRespondent &&
                      _ctx.data.section_spent_times &&
                      _ctx.data.section_spent_times.acceptable_behaviors !== null
                    )
                        ? (_openBlock(), _createElementBlock("div", _hoisted_199, [
                            _createElementVNode("span", _hoisted_200, _toDisplayString(_ctx.$t("assessmentResults.Completion_Time")) + ":", 1),
                            _createElementVNode("span", _hoisted_201, _toDisplayString(_ctx.showHumanTime(
                        _ctx.data.section_spent_times.acceptable_behaviors,
                        "acceptable_behaviors"
                      )), 1)
                          ]))
                        : _createCommentVNode("", true),
                      (!_ctx.isRespondent)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_202, [
                            _createElementVNode("span", null, _toDisplayString(_ctx.$t("assessmentResults.Overall")) + ":", 1),
                            _createElementVNode("span", null, _toDisplayString(Math.round(_ctx.data.summaries.acceptable_behaviors.overall)) + "%", 1)
                          ]))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_203, _toDisplayString(_ctx.$t("assessmentResults.Acceptable_Behaviors_Info")), 1)
                ]),
                _createElementVNode("div", _hoisted_204, [
                  _createElementVNode("div", _hoisted_205, [
                    _createElementVNode("div", _hoisted_206, [
                      _createElementVNode("span", _hoisted_207, _toDisplayString(_ctx.$t("question.acceptableBehaviors.Scenario")), 1)
                    ]),
                    _createElementVNode("div", _hoisted_208, _toDisplayString(_ctx.focusUser.companyName), 1),
                    _createElementVNode("div", _hoisted_209, _toDisplayString(_ctx.focusUser.firstname), 1)
                  ]),
                  (
                  _ctx.summaries &&
                  _ctx.summaries.acceptable_behaviors &&
                  _ctx.summaries.acceptable_behaviors.scenarios
                )
                    ? (_openBlock(), _createElementBlock("div", _hoisted_210, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.summaries.acceptable_behaviors.scenarios, (value) => {
                          return (_openBlock(), _createElementBlock("div", {
                            key: value.id,
                            class: "acceptable-behaviors-grid"
                          }, [
                            _createElementVNode("div", _hoisted_211, [
                              _createElementVNode("div", _hoisted_212, _toDisplayString(_ctx.$t("question.acceptableBehaviors.Scenario")) + " " + _toDisplayString(value.order), 1),
                              _createElementVNode("div", _hoisted_213, _toDisplayString(_ctx.lang(value, "description")), 1)
                            ]),
                            _createElementVNode("div", _hoisted_214, [
                              _createElementVNode("div", {
                                class: _normalizeClass(["tag-box", value.companyStatus])
                              }, [
                                _createElementVNode("span", _hoisted_215, _toDisplayString(_ctx.lang(value, "companyValueLabel")), 1)
                              ], 2)
                            ]),
                            (!_ctx.isRespondent)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_216, [
                                  _createElementVNode("div", {
                                    class: _normalizeClass(["tag-box flex-center", value.userStatus])
                                  }, [
                                    _createElementVNode("div", _hoisted_217, _toDisplayString(_ctx.lang(value, "userValueLabel")), 1),
                                    _createVNode(_component_el_dropdown, { onCommand: _ctx.changeAcceptableScore }, {
                                      dropdown: _withCtx(() => [
                                        _createVNode(_component_el_dropdown_menu, null, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_el_dropdown_item, {
                                              command: { data: value, command: 'Acceptable' }
                                            }, {
                                              default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(_ctx.$t("question.acceptableBehaviors.Acceptable")), 1)
                                              ]),
                                              _: 2
                                            }, 1032, ["command"]),
                                            _createVNode(_component_el_dropdown_item, {
                                              command: {
                                data: value,
                                command: 'Frowned Upon',
                              }
                                            }, {
                                              default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(_ctx.$t("question.acceptableBehaviors.Frowned_Upon")), 1)
                                              ]),
                                              _: 2
                                            }, 1032, ["command"]),
                                            _createVNode(_component_el_dropdown_item, {
                                              command: {
                                data: value,
                                command: 'Actively Discouraged',
                              }
                                            }, {
                                              default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(_ctx.$t(
                                  "question.acceptableBehaviors.Actively_Discouraged"
                                )), 1)
                                              ]),
                                              _: 2
                                            }, 1032, ["command"]),
                                            _createVNode(_component_el_dropdown_item, {
                                              command: { data: value, command: 'Reprimanded' }
                                            }, {
                                              default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(_ctx.$t("question.acceptableBehaviors.Reprimanded")), 1)
                                              ]),
                                              _: 2
                                            }, 1032, ["command"]),
                                            _createVNode(_component_el_dropdown_item, {
                                              command: {
                                data: value,
                                command: 'Zero Tolerance Policy',
                              }
                                            }, {
                                              default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(_ctx.$t(
                                  "question.acceptableBehaviors.Zero_Tolerance_Policy"
                                )), 1)
                                              ]),
                                              _: 2
                                            }, 1032, ["command"])
                                          ]),
                                          _: 2
                                        }, 1024)
                                      ]),
                                      default: _withCtx(() => [
                                        false
                                          ? (_openBlock(), _createElementBlock("img", _hoisted_218))
                                          : _createCommentVNode("", true),
                                        (_openBlock(), _createElementBlock("svg", _hoisted_219, _cache[10] || (_cache[10] = [
                                          _createElementVNode("path", {
                                            d: "M2.66699 13.3319H5.33366L12.8623 5.80333C13.1226 5.54299 13.1226 5.12088 12.8623 4.86053L11.1384 3.13667C10.878 2.87632 10.4559 2.87632 10.1956 3.13667L2.66699 10.6653V13.3319Z",
                                            stroke: "#696C80",
                                            "stroke-width": "1.4",
                                            "stroke-linecap": "round",
                                            "stroke-linejoin": "round"
                                          }, null, -1),
                                          _createElementVNode("path", {
                                            d: "M8 5.33203L10.6667 7.9987",
                                            stroke: "#696C80",
                                            "stroke-width": "1.4",
                                            "stroke-linecap": "round",
                                            "stroke-linejoin": "round"
                                          }, null, -1)
                                        ])))
                                      ]),
                                      _: 2
                                    }, 1032, ["onCommand"])
                                  ], 2)
                                ]))
                              : (_openBlock(), _createElementBlock("div", _hoisted_220, [
                                  _createElementVNode("div", {
                                    class: _normalizeClass(["tag-box", value.userStatus])
                                  }, [
                                    _createElementVNode("span", _hoisted_221, _toDisplayString(_ctx.lang(value, "userValueLabel")), 1)
                                  ], 2)
                                ]))
                          ]))
                        }), 128))
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_222, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList([...Array(15).keys()], (idx) => {
                          return (_openBlock(), _createElementBlock("div", {
                            key: idx,
                            class: "acceptable-behaviors-grid opa-04"
                          }, _cache[11] || (_cache[11] = [
                            _createStaticVNode("<div class=\"text-content\" data-v-8efd2cc8><div class=\"scenario-title\" data-v-8efd2cc8><div class=\"text-skeleton mt-4\" style=\"width:100%;\" data-v-8efd2cc8>   </div></div><div class=\"scenario-des\" data-v-8efd2cc8><div class=\"text-skeleton mt-4\" style=\"width:100%;\" data-v-8efd2cc8>   </div><div class=\"text-skeleton mt-4\" style=\"width:80%;\" data-v-8efd2cc8>   </div></div></div><div class=\"result flex-center\" data-v-8efd2cc8><div class=\"tag-box\" data-v-8efd2cc8><span class=\"text-skeleton mt-4\" style=\"width:3rem;\" data-v-8efd2cc8>   </span></div></div><div class=\"result flex-center\" data-v-8efd2cc8><div class=\"tag-box\" data-v-8efd2cc8><span class=\"text-skeleton mt-4\" style=\"width:3rem;\" data-v-8efd2cc8>   </span></div></div>", 3)
                          ])))
                        }), 128))
                      ]))
                ])
              ])
            ]),
            (
            Object.prototype.hasOwnProperty.call(
              _ctx.data,
              'acceptable_behaviors_feedback'
            ) && _ctx.data.acceptable_behaviors_feedback
          )
              ? (_openBlock(), _createElementBlock("div", _hoisted_223, [
                  _createElementVNode("div", _hoisted_224, _toDisplayString(_ctx.$t("assessmentTest.Anyz_feedback_or_thoughts")) + _toDisplayString(_ctx.$t("assessmentTest.acceptable_behaviors_q")), 1),
                  _createElementVNode("div", _hoisted_225, _toDisplayString(_ctx.data.acceptable_behaviors_feedback), 1)
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      (_ctx.data.summaries && _ctx.data.summaries.human_skills)
        ? (_openBlock(), _createElementBlock("div", _hoisted_226, [
            _createElementVNode("div", _hoisted_227, [
              _createElementVNode("div", _hoisted_228, [
                _createElementVNode("div", _hoisted_229, [
                  _createElementVNode("div", _hoisted_230, [
                    _createElementVNode("div", _hoisted_231, _toDisplayString(_ctx.$t("main.Human_Skills")), 1),
                    _createElementVNode("div", _hoisted_232, [
                      (
                      !_ctx.isRespondent &&
                      _ctx.data.section_spent_times &&
                      _ctx.data.section_spent_times.human_skills !== null
                    )
                        ? (_openBlock(), _createElementBlock("div", _hoisted_233, [
                            _createElementVNode("span", _hoisted_234, _toDisplayString(_ctx.$t("assessmentResults.Completion_Time")) + ":", 1),
                            _createElementVNode("span", _hoisted_235, _toDisplayString(_ctx.showHumanTime(
                        _ctx.data.section_spent_times.human_skills,
                        "human_skills"
                      )), 1)
                          ]))
                        : _createCommentVNode("", true),
                      (!_ctx.isRespondent)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_236, [
                            _createElementVNode("span", null, _toDisplayString(_ctx.$t("assessmentResults.Overall")) + ":", 1),
                            _createElementVNode("span", null, _toDisplayString(Math.round(_ctx.data.summaries.human_skills.overall)) + "%", 1)
                          ]))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_237, _toDisplayString(_ctx.$t("assessmentResults.The_candidate_human_skills_are")), 1)
                ])
              ]),
              _createElementVNode("div", null, [
                _createElementVNode("div", _hoisted_238, _toDisplayString(_ctx.$t("assessmentTest.Describe_a_time_when_you")), 1),
                (_ctx.data && _ctx.data.human_skills && _ctx.data.human_skills.response_1)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_239, _toDisplayString(_ctx.data.human_skills.response_1), 1))
                  : (_openBlock(), _createElementBlock("div", _hoisted_240, _cache[12] || (_cache[12] = [
                      _createStaticVNode("<div class=\"\" data-v-8efd2cc8><div style=\"width:100%;\" data-v-8efd2cc8><div class=\"text-skeleton mt-4\" style=\"width:100%;\" data-v-8efd2cc8>   </div><div class=\"text-skeleton mt-4\" style=\"width:100%;\" data-v-8efd2cc8>   </div><div class=\"text-skeleton mt-4\" style=\"width:100%;\" data-v-8efd2cc8>   </div><div class=\"text-skeleton mt-4\" style=\"width:60%;\" data-v-8efd2cc8>   </div></div></div>", 1)
                    ]))),
                _createElementVNode("div", _hoisted_241, _toDisplayString(_ctx.$t("assessmentTest.Imagine_you_are_working_on")), 1),
                (_ctx.data && _ctx.data.human_skills && _ctx.data.human_skills.response_2)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_242, _toDisplayString(_ctx.data.human_skills.response_2), 1))
                  : (_openBlock(), _createElementBlock("div", _hoisted_243, _cache[13] || (_cache[13] = [
                      _createStaticVNode("<div class=\"\" data-v-8efd2cc8><div style=\"width:100%;\" data-v-8efd2cc8><div class=\"text-skeleton mt-4\" style=\"width:100%;\" data-v-8efd2cc8>   </div><div class=\"text-skeleton mt-4\" style=\"width:100%;\" data-v-8efd2cc8>   </div><div class=\"text-skeleton mt-4\" style=\"width:100%;\" data-v-8efd2cc8>   </div><div class=\"text-skeleton mt-4\" style=\"width:30%;\" data-v-8efd2cc8>   </div></div></div>", 1)
                    ])))
              ])
            ]),
            _createElementVNode("div", _hoisted_244, [
              (_ctx.skills.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_245, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.skills, (skill, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "skill-box card-box",
                        style: _normalizeStyle(`visibility: ${
                skill.visibility !== false ? 'visible' : 'hidden'
              }`),
                        key: skill.label + '_' + index
                      }, [
                        _createElementVNode("div", _hoisted_246, [
                          _createTextVNode(_toDisplayString(skill.label) + " ", 1),
                          (skill.value)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_247, " (" + _toDisplayString(skill.value) + "%)", 1))
                            : _createCommentVNode("", true)
                        ]),
                        (skill.visibility !== false)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_248, [
                              _createElementVNode("div", {
                                class: "bar-box box-left",
                                style: _normalizeStyle(_ctx.checkOpacityAndColor(skill.value, 20))
                              }, null, 4),
                              _createElementVNode("div", {
                                class: "bar-box",
                                style: _normalizeStyle(_ctx.checkOpacityAndColor(skill.value, 40))
                              }, null, 4),
                              _createElementVNode("div", {
                                class: "bar-box",
                                style: _normalizeStyle(_ctx.checkOpacityAndColor(skill.value, 60))
                              }, null, 4),
                              _createElementVNode("div", {
                                class: "bar-box",
                                style: _normalizeStyle(_ctx.checkOpacityAndColor(skill.value, 80))
                              }, null, 4),
                              _createElementVNode("div", {
                                class: "bar-box box-right",
                                style: _normalizeStyle(_ctx.checkOpacityAndColor(skill.value, 100))
                              }, null, 4),
                              (skill.value)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_249, _toDisplayString(skill.value) + "% ", 1))
                                : (_openBlock(), _createElementBlock("div", _hoisted_250, " "))
                            ]))
                          : _createCommentVNode("", true),
                        _createElementVNode("div", _hoisted_251, _toDisplayString(skill.content), 1)
                      ], 4))
                    }), 128))
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_252, [
                    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(['06', '04', '06', '04'], (opa, index) => {
                      return _createElementVNode("div", {
                        class: "skill-box card-box",
                        key: opa + '_' + index
                      }, [
                        _cache[15] || (_cache[15] = _createElementVNode("div", { class: "header" }, [
                          _createElementVNode("div", {
                            class: "text-skeleton",
                            style: {"width":"60%"}
                          }, " ")
                        ], -1)),
                        _createElementVNode("div", _hoisted_253, [
                          _createElementVNode("div", {
                            class: "bar-box box-left",
                            style: _normalizeStyle(_ctx.checkOpacityAndColor(100, 20))
                          }, null, 4),
                          _createElementVNode("div", {
                            class: "bar-box",
                            style: _normalizeStyle(_ctx.checkOpacityAndColor(100, 40))
                          }, null, 4),
                          _createElementVNode("div", {
                            class: "bar-box",
                            style: _normalizeStyle(_ctx.checkOpacityAndColor(100, 60))
                          }, null, 4),
                          _createElementVNode("div", {
                            class: "bar-box",
                            style: _normalizeStyle(_ctx.checkOpacityAndColor(100, 80))
                          }, null, 4),
                          _createElementVNode("div", {
                            class: "bar-box box-right",
                            style: _normalizeStyle(_ctx.checkOpacityAndColor(100, 100))
                          }, null, 4)
                        ]),
                        _createElementVNode("div", {
                          class: _normalizeClass(["fs-14", `opa-${opa}`])
                        }, _cache[14] || (_cache[14] = [
                          _createStaticVNode("<div style=\"width:100%;\" data-v-8efd2cc8><div class=\"text-skeleton mt-4\" style=\"width:100%;\" data-v-8efd2cc8>   </div><div class=\"text-skeleton mt-4\" style=\"width:100%;\" data-v-8efd2cc8>   </div><div class=\"text-skeleton mt-4\" style=\"width:100%;\" data-v-8efd2cc8>   </div><div class=\"text-skeleton mt-4\" style=\"width:80%;\" data-v-8efd2cc8>   </div></div>", 1)
                        ]), 2)
                      ])
                    }), 64))
                  ]))
            ]),
            (
            Object.prototype.hasOwnProperty.call(
              _ctx.data,
              'human_skills_feedback'
            ) && _ctx.data.human_skills_feedback
          )
              ? (_openBlock(), _createElementBlock("div", _hoisted_254, [
                  _createElementVNode("div", _hoisted_255, _toDisplayString(_ctx.$t("assessmentTest.Anyz_feedback_or_thoughts")) + _toDisplayString(_ctx.$t("assessmentTest.human_skills_q")), 1),
                  _createElementVNode("div", _hoisted_256, _toDisplayString(_ctx.data.human_skills_feedback), 1)
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      (_ctx.data && _ctx.data.summaries && _ctx.data.summaries.logic_test)
        ? (_openBlock(), _createElementBlock("div", _hoisted_257, [
            _createElementVNode("div", _hoisted_258, [
              _createElementVNode("div", _hoisted_259, [
                _createElementVNode("div", _hoisted_260, [
                  _createElementVNode("div", _hoisted_261, _toDisplayString(_ctx.$t("main.Logic_Test")), 1),
                  _createElementVNode("div", _hoisted_262, [
                    (
                    !_ctx.isRespondent &&
                    _ctx.data.section_spent_times &&
                    _ctx.data.section_spent_times.logic_test !== null
                  )
                      ? (_openBlock(), _createElementBlock("div", _hoisted_263, [
                          _createElementVNode("span", _hoisted_264, _toDisplayString(_ctx.$t("assessmentResults.Completion_Time")) + ":", 1),
                          _createElementVNode("span", _hoisted_265, _toDisplayString(_ctx.showHumanTime(
                      _ctx.data.section_spent_times.logic_test,
                      "logic_test"
                    )), 1)
                        ]))
                      : _createCommentVNode("", true),
                    (!_ctx.isRespondent)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_266, [
                          _createElementVNode("span", null, _toDisplayString(_ctx.$t("assessmentResults.Overall")) + ":", 1),
                          _createElementVNode("span", null, _toDisplayString(Math.round(_ctx.data.summaries.logic_test.overall)) + "%", 1)
                        ]))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _createElementVNode("div", _hoisted_267, [
                  _createElementVNode("div", null, [
                    _createElementVNode("div", _hoisted_268, [
                      _createElementVNode("div", _hoisted_269, [
                        _createElementVNode("div", _hoisted_270, [
                          _createElementVNode("div", _hoisted_271, [
                            _createElementVNode("div", _hoisted_272, [
                              _createElementVNode("div", _hoisted_273, [
                                _createElementVNode("div", _hoisted_274, _toDisplayString(_ctx.$t("assessmentResults.Pattern_Recognition")), 1)
                              ]),
                              _createElementVNode("div", _hoisted_275, _toDisplayString(_ctx.$t("assessmentResults.trend_analysis")), 1)
                            ])
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_276, [
                          _createElementVNode("div", _hoisted_277, [
                            _createElementVNode("div", _hoisted_278, [
                              _createElementVNode("div", _hoisted_279, [
                                _createElementVNode("div", _hoisted_280, _toDisplayString(_ctx.$t("assessmentResults.Problem_Resolving")), 1),
                                _createElementVNode("div", _hoisted_281, _toDisplayString(_ctx.$t("assessmentResults.strategic_planning")), 1)
                              ])
                            ])
                          ]),
                          _createElementVNode("div", null, [
                            _createElementVNode("canvas", _hoisted_282, null, 512)
                          ]),
                          _createElementVNode("div", _hoisted_283, [
                            _createElementVNode("div", _hoisted_284, [
                              _createElementVNode("div", _hoisted_285, [
                                _createElementVNode("div", _hoisted_286, _toDisplayString(_ctx.$t("assessmentResults.Logical_Reasoning")), 1),
                                _createElementVNode("div", _hoisted_287, _toDisplayString(_ctx.$t("assessmentResults.decision_making")), 1)
                              ])
                            ])
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_288, [
                          _createElementVNode("div", _hoisted_289, [
                            _createElementVNode("div", _hoisted_290, [
                              _createElementVNode("div", _hoisted_291, _toDisplayString(_ctx.$t("assessmentResults.Data_Interpretation")), 1),
                              _createElementVNode("div", _hoisted_292, _toDisplayString(_ctx.$t("assessmentResults.business_intelligence")), 1)
                            ])
                          ])
                        ])
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_293, [
                    _createElementVNode("div", _hoisted_294, [
                      _createElementVNode("div", _hoisted_295, [
                        _createElementVNode("div", _hoisted_296, [
                          _createElementVNode("div", _hoisted_297, [
                            _createTextVNode(_toDisplayString(_ctx.$t("assessmentResults.Pattern_Recognition")) + " ", 1),
                            (_ctx.logictestSection && _ctx.data && _ctx.data.logic_test)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_298, _toDisplayString(_ctx.getLogicTestScoreBySectionOrder(1)), 1))
                              : _createCommentVNode("", true)
                          ]),
                          (_ctx.logictestSection && _ctx.data && _ctx.data.logic_test)
                            ? (_openBlock(), _createBlock(_component_logic_test_scores, {
                                key: 0,
                                sections: _ctx.data.logic_test,
                                logicTestSection: _ctx.logictestSection,
                                data: _ctx.data,
                                order: 1,
                                disabledTooltip: false
                              }, null, 8, ["sections", "logicTestSection", "data"]))
                            : _createCommentVNode("", true),
                          _createElementVNode("div", _hoisted_299, _toDisplayString(_ctx.$t("assessmentResults.pattern_recognition_detail")), 1)
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_300, [
                        _createElementVNode("div", _hoisted_301, [
                          _createElementVNode("div", _hoisted_302, [
                            _createTextVNode(_toDisplayString(_ctx.$t("assessmentResults.Logical_Reasoning")) + " ", 1),
                            (_ctx.logictestSection && _ctx.data && _ctx.data.logic_test)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_303, _toDisplayString(_ctx.getLogicTestScoreBySectionOrder(2)), 1))
                              : _createCommentVNode("", true)
                          ]),
                          (_ctx.logictestSection && _ctx.data && _ctx.data.logic_test)
                            ? (_openBlock(), _createBlock(_component_logic_test_scores, {
                                key: 0,
                                sections: _ctx.data.logic_test,
                                logicTestSection: _ctx.logictestSection,
                                data: _ctx.data,
                                order: 2,
                                disabledTooltip: false
                              }, null, 8, ["sections", "logicTestSection", "data"]))
                            : _createCommentVNode("", true),
                          _createElementVNode("div", _hoisted_304, _toDisplayString(_ctx.$t("assessmentResults.logical_reasoning_detail")), 1)
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_305, [
                        _createElementVNode("div", _hoisted_306, [
                          _createElementVNode("div", _hoisted_307, [
                            _createTextVNode(_toDisplayString(_ctx.$t("assessmentResults.Problem_Resolving")) + " ", 1),
                            (_ctx.logictestSection && _ctx.data && _ctx.data.logic_test)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_308, _toDisplayString(_ctx.getLogicTestScoreBySectionOrder(3)), 1))
                              : _createCommentVNode("", true)
                          ]),
                          (_ctx.logictestSection && _ctx.data && _ctx.data.logic_test)
                            ? (_openBlock(), _createBlock(_component_logic_test_scores, {
                                key: 0,
                                sections: _ctx.data.logic_test,
                                logicTestSection: _ctx.logictestSection,
                                data: _ctx.data,
                                order: 3,
                                disabledTooltip: false
                              }, null, 8, ["sections", "logicTestSection", "data"]))
                            : _createCommentVNode("", true),
                          _createElementVNode("div", _hoisted_309, _toDisplayString(_ctx.$t("assessmentResults.problem_pesolving_detail")), 1)
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_310, [
                        _createElementVNode("div", _hoisted_311, [
                          _createElementVNode("div", _hoisted_312, [
                            _createTextVNode(_toDisplayString(_ctx.$t("assessmentResults.Data_Interpretation")) + " ", 1),
                            (_ctx.logictestSection && _ctx.data && _ctx.data.logic_test)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_313, _toDisplayString(_ctx.getLogicTestScoreBySectionOrder(4)), 1))
                              : _createCommentVNode("", true)
                          ]),
                          (_ctx.logictestSection && _ctx.data && _ctx.data.logic_test)
                            ? (_openBlock(), _createBlock(_component_logic_test_scores, {
                                key: 0,
                                sections: _ctx.data.logic_test,
                                logicTestSection: _ctx.logictestSection,
                                data: _ctx.data,
                                order: 4,
                                disabledTooltip: false
                              }, null, 8, ["sections", "logicTestSection", "data"]))
                            : _createCommentVNode("", true),
                          _createElementVNode("div", _hoisted_314, _toDisplayString(_ctx.$t("assessmentResults.data_interpretation_detail")), 1)
                        ])
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.data && _ctx.data.summaries && _ctx.data.summaries.custom_survey)
        ? (_openBlock(), _createElementBlock("div", _hoisted_315, [
            _createElementVNode("div", _hoisted_316, [
              _createElementVNode("div", _hoisted_317, [
                _createElementVNode("div", _hoisted_318, [
                  (_ctx.formTemplate && _ctx.formTemplate.custom_survey_title)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_319, _toDisplayString(_ctx.formTemplate.custom_survey_title), 1))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_320, [
                    (
                    !_ctx.isRespondent &&
                    _ctx.data.section_spent_times &&
                    _ctx.data.section_spent_times.custom_survey !== null
                  )
                      ? (_openBlock(), _createElementBlock("div", _hoisted_321, [
                          _createElementVNode("span", _hoisted_322, _toDisplayString(_ctx.$t("assessmentResults.Completion_Time")) + ":", 1),
                          _createElementVNode("span", _hoisted_323, _toDisplayString(_ctx.showHumanTime(
                      _ctx.data.section_spent_times.custom_survey,
                      "custom_survey"
                    )), 1)
                        ]))
                      : _createCommentVNode("", true)
                  ])
                ]),
                (_ctx.formTemplate && _ctx.formTemplate.custom_survey_description)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_324, _toDisplayString(_ctx.formTemplate.custom_survey_description), 1))
                  : _createCommentVNode("", true),
                (_ctx.formTemplate && _ctx.formTemplate.custom_survey_custom_fields)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_325, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formTemplate.custom_survey_custom_fields, (field, index) => {
                        return (_openBlock(), _createElementBlock("div", {
                          class: "custom-survey-field",
                          key: `custom-survey-field-${index}`
                        }, [
                          _createElementVNode("div", _hoisted_326, [
                            _createElementVNode("div", _hoisted_327, _toDisplayString((index + 1).toString().padStart(2, "0")), 1),
                            _createElementVNode("div", _hoisted_328, [
                              _createElementVNode("div", _hoisted_329, [
                                _createElementVNode("div", _hoisted_330, _toDisplayString(_ctx.$t(`setup.customSurvey.questionTypes.${field.type}`)), 1),
                                _createElementVNode("div", _hoisted_331, _toDisplayString(field.question), 1),
                                _createElementVNode("div", _hoisted_332, _toDisplayString(field.description), 1),
                                (field.type === 'open-ended')
                                  ? (_openBlock(), _createElementBlock("div", {
                                      key: 0,
                                      class: _normalizeClass(`answer ${field.type}`)
                                    }, _toDisplayString(_ctx.customSurveyGetAnswer(`scenario_${index + 1}`)), 3))
                                  : _createCommentVNode("", true),
                                (field.type === 'multiple-choice')
                                  ? (_openBlock(), _createElementBlock("div", {
                                      key: 1,
                                      class: _normalizeClass(`answer ${field.type}`)
                                    }, [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(field.choices, (choice, choiceIndex) => {
                                        return (_openBlock(), _createElementBlock("div", {
                                          key: `choice-${choiceIndex}`,
                                          class: _normalizeClass(`choice ${
                            choice.isCorrect ? 'correct' : 'incorrect'
                          }`)
                                        }, [
                                          (
                              _ctx.customSurveyIsCheeck(
                                `scenario_${index + 1}`,
                                choiceIndex
                              )
                            )
                                            ? (_openBlock(), _createElementBlock("svg", _hoisted_333, [
                                                _createElementVNode("rect", {
                                                  x: "2.25",
                                                  y: "2.75",
                                                  width: "9.5",
                                                  height: "9.5",
                                                  rx: "4.75",
                                                  stroke: _ctx.companyColor,
                                                  "stroke-width": "4.5"
                                                }, null, 8, _hoisted_334)
                                              ]))
                                            : (_openBlock(), _createElementBlock("svg", _hoisted_335, _cache[16] || (_cache[16] = [
                                                _createElementVNode("rect", {
                                                  x: "0.7",
                                                  y: "0.7",
                                                  width: "12.6",
                                                  height: "12.6",
                                                  rx: "6.3",
                                                  stroke: "#E6E8EC",
                                                  "stroke-width": "1.4"
                                                }, null, -1)
                                              ]))),
                                          _createTextVNode(" " + _toDisplayString(choice.value), 1)
                                        ], 2))
                                      }), 128))
                                    ], 2))
                                  : _createCommentVNode("", true),
                                (field.type === 'multiple-select')
                                  ? (_openBlock(), _createElementBlock("div", {
                                      key: 2,
                                      class: _normalizeClass(`answer ${field.type}`)
                                    }, [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(field.choices, (choice, choiceIndex) => {
                                        return (_openBlock(), _createElementBlock("div", {
                                          key: `choice-${choiceIndex}`,
                                          class: _normalizeClass(`choice ${
                            choice.isCorrect ? 'correct' : 'incorrect'
                          }`)
                                        }, [
                                          (
                              _ctx.customSurveyIsCheeck(
                                `scenario_${index + 1}`,
                                choiceIndex
                              )
                            )
                                            ? (_openBlock(), _createElementBlock("svg", _hoisted_336, [
                                                _createElementVNode("rect", {
                                                  width: "14",
                                                  height: "14",
                                                  rx: "4",
                                                  fill: _ctx.companyColor
                                                }, null, 8, _hoisted_337),
                                                _cache[17] || (_cache[17] = _createElementVNode("path", {
                                                  d: "M6.33324 8.05631L9.39737 4.99219L9.86877 5.46359L6.33324 8.99911L4.21191 6.87781L4.68332 6.40641L6.33324 8.05631Z",
                                                  fill: "white"
                                                }, null, -1))
                                              ]))
                                            : (_openBlock(), _createElementBlock("svg", _hoisted_338, _cache[18] || (_cache[18] = [
                                                _createElementVNode("rect", {
                                                  x: "0.7",
                                                  y: "0.7",
                                                  width: "12.6",
                                                  height: "12.6",
                                                  rx: "3.3",
                                                  stroke: "#E6E8EC",
                                                  "stroke-width": "1.4"
                                                }, null, -1)
                                              ]))),
                                          _createTextVNode(" " + _toDisplayString(choice.value), 1)
                                        ], 2))
                                      }), 128))
                                    ], 2))
                                  : _createCommentVNode("", true)
                              ])
                            ])
                          ])
                        ]))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true)
              ])
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.data && _ctx.data.summaries && _ctx.data.summaries.work_style_identifier)
        ? (_openBlock(), _createElementBlock("div", _hoisted_339, [
            _createElementVNode("div", _hoisted_340, [
              _createElementVNode("div", _hoisted_341, [
                _createElementVNode("div", _hoisted_342, [
                  _createElementVNode("div", _hoisted_343, [
                    _createElementVNode("canvas", _hoisted_344, null, 512),
                    _createElementVNode("div", _hoisted_345, [
                      false
                        ? (_openBlock(), _createElementBlock("div", _hoisted_346, [
                            _createElementVNode("div", null, _toDisplayString(_ctx.$t("assessmentResults.Work_Style__")), 1),
                            _createElementVNode("div", null, _toDisplayString(_ctx.$t("assessmentResults.Composition__")), 1)
                          ]))
                        : _createCommentVNode("", true)
                    ])
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_347, [
                _createElementVNode("div", _hoisted_348, [
                  _createElementVNode("div", _hoisted_349, [
                    _createElementVNode("div", _hoisted_350, _toDisplayString(_ctx.$t("main.Work_Style_Identifier")), 1),
                    _createElementVNode("div", _hoisted_351, [
                      (
                      !_ctx.isRespondent &&
                      _ctx.data.section_spent_times &&
                      _ctx.data.section_spent_times.work_style_identifier !== null
                    )
                        ? (_openBlock(), _createElementBlock("div", _hoisted_352, [
                            _createElementVNode("span", _hoisted_353, _toDisplayString(_ctx.$t("assessmentResults.Completion_Time")) + ":", 1),
                            _createElementVNode("span", _hoisted_354, _toDisplayString(_ctx.showHumanTime(
                        _ctx.data.section_spent_times.work_style_identifier,
                        "work_style_identifier"
                      )), 1)
                          ]))
                        : _createCommentVNode("", true),
                      (!_ctx.isRespondent)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_355, [
                            _createElementVNode("span", null, _toDisplayString(_ctx.data.firstname) + " is " + _toDisplayString(_ctx.workStyleFocusedType), 1)
                          ]))
                        : _createCommentVNode("", true)
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_356, _toDisplayString(_ctx.$t("assessmentResults.The_candidate_primary_work_style")), 1),
                _createElementVNode("div", _hoisted_357, [
                  _createElementVNode("div", _hoisted_358, [
                    _cache[19] || (_cache[19] = _createElementVNode("div", {
                      class: "pointer",
                      style: {"background-color":"#ffc200"}
                    }, null, -1)),
                    _createElementVNode("div", _hoisted_359, _toDisplayString(_ctx.$t("assessmentResults.Product")) + ", " + _toDisplayString(Math.round(
                      _ctx.data.summaries.work_style_identifier.overall.product * 100
                    )) + "% ", 1)
                  ]),
                  _createElementVNode("div", _hoisted_360, _toDisplayString(_ctx.$t("assessmentResults.Doers_and_executors_They")), 1)
                ]),
                _createElementVNode("div", _hoisted_361, [
                  _createElementVNode("div", _hoisted_362, [
                    _cache[20] || (_cache[20] = _createElementVNode("div", {
                      class: "pointer",
                      style: {"background-color":"#ff4343"}
                    }, null, -1)),
                    _createElementVNode("div", _hoisted_363, _toDisplayString(_ctx.$t("assessmentResults.Process")) + ", " + _toDisplayString(Math.round(
                      _ctx.data.summaries.work_style_identifier.overall.process * 100
                    )) + "% ", 1)
                  ]),
                  _createElementVNode("div", _hoisted_364, _toDisplayString(_ctx.$t("assessmentResults.Learners_and_thinkers_They")), 1)
                ]),
                _createElementVNode("div", _hoisted_365, [
                  _createElementVNode("div", _hoisted_366, [
                    _cache[21] || (_cache[21] = _createElementVNode("div", {
                      class: "pointer",
                      style: {"background-color":"#5eb8f6"}
                    }, null, -1)),
                    _createElementVNode("div", _hoisted_367, _toDisplayString(_ctx.$t("assessmentResults.People")) + ", " + _toDisplayString(Math.round(
                      _ctx.data.summaries.work_style_identifier.overall.people * 100
                    )) + "% ", 1)
                  ]),
                  _createElementVNode("div", _hoisted_368, _toDisplayString(_ctx.$t("assessmentResults.Relationship_builders_They")), 1)
                ])
              ])
            ]),
            (
            Object.prototype.hasOwnProperty.call(
              _ctx.data,
              'work_style_identifier_feedback'
            ) && _ctx.data.work_style_identifier_feedback
          )
              ? (_openBlock(), _createElementBlock("div", _hoisted_369, [
                  _createElementVNode("div", _hoisted_370, _toDisplayString(_ctx.$t("assessmentTest.Anyz_feedback_or_thoughts")) + _toDisplayString(_ctx.$t("assessmentTest.work_style_identifier_q")), 1),
                  _createElementVNode("div", _hoisted_371, _toDisplayString(_ctx.data.work_style_identifier_feedback), 1)
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}